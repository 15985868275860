import { Button, Modal, useMediaQuery } from '@mui/material';
import styled from 'styled-components'
import DiscountIcon from '../../img/DiscountIcon.svg';
import DocumentIcon from '../../img/DocumentIcon.svg';
import CallIcon from '../../img/CallIcon.svg';
import SignaturesIcon from '../../img/SignaturesIcon.svg';
import { Center, Stack} from '@mantine/core';
import { useProposal } from '../../providers/ProposalProvider';


const ModalContainer = styled.div`
    height: 530px;
    width: 370px;

    background-color: #F9F9F9;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 20px;

    @media (min-width: 768px) {
        height: 1150px;
        width: 700px;
    }

    @media (min-width: 992px) {
        height: 1200px;
        width: 850px;
    }

    @media (min-width: 1300px) {
        height: 660px;
        width: 900px;
    }
`

const Logo = styled.img`
    height: 70px;
    width: 70px;
    padding:20px;

    position: absolute;
    left: -50px;
`

const IconContainer = styled.div`

    width: 400px;
    height: 850px;

    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row: auto;
    grid-column-gap: 50px;
    grid-row-gap: 5px;

    margin: 0 auto;

    @media (min-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);

        width: 800px;
        height: 320px;

        margin: 20px auto 0 80px;
    }
`

const TextContainer = styled.div`
    width: 340px;
    height: 130px;

    background-color: #fff;

    display:flex;
    align-items:center;
    justify-content:center;

    position: relative;

    border-radius: 40px;

`

const BoxTitle = styled.h1`

    color: #17C0B9;
    text-align: center;
`

const BoxText = styled.p`
    color: #454545;

    text-align: center;
    padding: 10px 20px;
    margin: 0 20px;
    font-size: 15px;
`


const Title = styled.h1`
    margin: 0 auto;
    font-size: 32px;
    width: fit-content;

    padding: 20px;

    color: #17C0B9;

    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 34px;
    }

`

const Subtitle = styled.p`
    margin: 0 auto;
    width: 350px;
    text-align: center;

    font-size: 18px;
    font-weight: 500;
    padding: 5px;
    line-height: 1.5;

    @media (min-width: 768px) {
        width: 600px;
    }

    @media (min-width: 992px) {
        width: 800px;
    }

`

const LegalNeeds = styled.p`
    font-size: 18px;
    font-weight: 400;
    
    padding: 0 0 30px 0;
    margin: 0 auto;

    @media (min-width: 1300px) {
        width: 650px;
    }
    
`


const ButtonCenter = styled(Center)`

`

const RemoveButton = styled(Button)`

    margin: 0 20px !important;
    color: black !important;
    height: 50px;
    width: 250px;
    font-weight: 600 !important;
    font-family: Montserrat !important;
    border-radius: 20px !important;
    border-color: #b8b8b8 !important;
`

const KeepButton = styled(Button)`
    background: #17C0B9 ${props => props.disabled ? "": "!important" };
    margin: 0 20px !important;
    color: white !important;
    height: 50px;
    width: 250px;
    font-weight: 600 !important;
    font-family: Montserrat !important;
    border-radius: 20px !important;
`

const MobileText = styled.p`
    font-size: 17px;


    padding: 10px 20px 40px 20px;
    line-height: 1.5;
    text-align: center;
`

const Price = styled.span`
    background: linear-gradient(260.02deg, #1F5D70 20.42%, rgba(31, 93, 112, 0) 92.64%), #278a86;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
`

const MobileLink = styled.a`
    color: #17C0B9;
    font-weight: 600;
`

interface ModalProps {
    open : boolean,
    handleClose : () => void
}

function RemoveMembershipModal(props : ModalProps){
    const { open, handleClose } = props;

    const { memberDiscount, memberPrice, setMemberUpsell, proposal} = useProposal();
    
    const mobile = useMediaQuery('(max-width:768px)');

    const handleRemove = () => {
        handleClose();
    };

    
    const handleAdd = () => {
        setMemberUpsell(true)
        handleClose();
    };

    const MemberLink = proposal.country === "AU" ? "https://www.sprintlaw.com.au/membership" : "https://www.sprintlaw.co.uku/membership";
    const Currency = proposal.country === "UK" ? "£" : "$";
    const SignaturePrice = proposal.country === "UK" ? "£1" : "$1.45";

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
            overflow: 'scroll',
        }}
        >
            <ModalContainer>
                <Title>
                    Are you sure?
                </Title>
                <Subtitle>
                    <LegalNeeds>By adding the Sprintlaw Membership, <Price>you'll save {Currency}{memberDiscount}</Price> on the project, and the first year fee will <Price>only cost {Currency}{memberPrice}</Price>{memberDiscount > memberPrice && <><br />(making it cheaper overall)</>}</LegalNeeds>
                </Subtitle>
                <Subtitle>
                    What's included?
                </Subtitle>

                {mobile && 
                <>
                    <MobileText>Members recieve <b>discounts</b> on our services, access to our <b>legal helpline</b>, <b>free documents</b> and <b>e-signatures</b>, and <MobileLink href={MemberLink} target="_blank" >more</MobileLink>. </MobileText>
                </>
                }
                {!mobile && 
                <IconContainer>
                    <TextContainer>
                        <Logo src={CallIcon} />
                        <Stack>
                            <BoxTitle>
                                Lawyer Calls
                            </BoxTitle>
                            <BoxText>
                                Book a free call with a lawyer whenever you need help, advice or answers.
                            </BoxText>
                        </Stack>
                    </TextContainer>
                    <TextContainer>
                        <Logo src={DiscountIcon} />
                        <Stack>
                            <BoxTitle>
                            Discounted Legal Work
                            </BoxTitle>
                            <BoxText>
                            Receive a 10% membership discount on all our fixed-fee legal packages.
                            </BoxText>
                        </Stack>
                    </TextContainer>
                    <TextContainer>
                        <Logo src={SignaturesIcon} />
                        <Stack>
                            <BoxTitle>
                            Sprintlaw Signatures
                            </BoxTitle>
                            <BoxText>
                            Access our eSignatures tool to get documents electronically signed and witnessed ({SignaturePrice} per doc)
                            </BoxText>
                        </Stack>
                    </TextContainer>
                    <TextContainer>
                        <Logo src={DocumentIcon} />
                        <Stack>
                            <BoxTitle>
                                Document Updates
                            </BoxTitle>
                            <BoxText>
                            Our lawyers can make free quick updates to any documents we’ve prepared for you.
                            </BoxText>
                        </Stack>
                    </TextContainer>
                </IconContainer>}
                <ButtonCenter>
                    <RemoveButton onClick={handleRemove} variant="outlined"> REMOVE IT </RemoveButton>
                    <KeepButton onClick={handleAdd}> KEEP IT </KeepButton>
                </ButtonCenter>

            </ModalContainer>
        </Modal>         
    )
}

export default RemoveMembershipModal;