import { IconButton, Tooltip } from "@mui/material"
import { MdOutlineHelp } from "react-icons/md"
import styled from "styled-components"

interface TooltipProps {
    label : string
    aria : string
}

const HelpTooltip = styled(IconTooltip)`
    float: right !important;
    height: 20px;
    width: 20px;

`
const Help = styled(MdOutlineHelp)`
    color: #17C0B9;
    width: 20px;
    height: 20px;

`
const TooltipButton = styled(IconButton)`
    padding: 0 10px 0 10px!important;
`
const TooltipText = styled.h1`
    font-size: 16px;
`

function IconTooltip(props : TooltipProps){
    const { label, aria} = props;

    return (
        <Tooltip title={<TooltipText>{label}</TooltipText>} 
            componentsProps={{
                tooltip: {
                    sx: {
                        lineHeight: '1.8rem',
                    },
                },
            }}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
          >
            <TooltipButton aria-label={aria}><Help /></TooltipButton>
        </Tooltip>
    )
}

export default HelpTooltip;