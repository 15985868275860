import { createContext, useContext, useState, FC, useMemo, useCallback } from "react";
import { IProduct, IProposal, ICompletedPayment } from "../types/Proposal";
import { completePayment, getProposal as getProposalAPI, getBrief as getBriefAPI } from '../services/APIService';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { PaymentIntent } from "@stripe/stripe-js";

import { useNavigate } from "react-router-dom";

export interface DetailsInput {
    first_name: string
    last_name: string
    email: string
    company_name: string
    address_line_1: string
    address_line_2: string
    city: string
    state: string
    post: string
    country: string
    company_number: string
    phone_number: string
}


interface IProposalContext {
    proposal: IProposal,
    loading: boolean,
    setLoading: Function,
    getProposal: Function,
    getBrief: Function,
    setProposal: Function,
    setProductSelected: Function,
    total: number,
    totalNoDiscount: number,
    found: boolean,
    getPrice: (product : IProduct) => number,
    gstTotal: number,
    gst: number,
    discountPrice: number,
    discounted: boolean,
    expired: boolean,
    expiryDate: Date,
    paidAt: Date,
    checkout: boolean,
    setCheckout: Function,
    products: IProduct[],
    optionalProducts: IProduct[],
    govProducts: IProduct[],
    selectedProducts: IProduct[],
    memberPrice: number,
    setMemberUpsell: Function,
    memberDiscount: number,
    memberPopup: boolean,
    setMemberPopup: Function,
    handleDetailsChange: Function,
    handlePhoneChange: Function,
    details: DetailsInput
    details_form?: any
    payment_completed: Function,
    paymentLoading: boolean,
    setPaymentLoading: Function,
    paymentSuccess: boolean,
    setPaymentSuccess: Function,
    taxType: string,
    currency: string
    express: boolean
    individual: boolean
    setIndividual: Function
    noCompanyNumber: boolean
    setNoCompanyNumber: Function
    manualAddress: boolean
    setManualAddress: Function
    skipBooking: boolean
    hideBookingAlert : boolean
}

const test_products : IProduct[] = [
    {
        name: "",
        price: 0,
        optional: false,
        selected: true,
        description: "",
        SKU: ""
    },
    {
        name: "",
        price: 0,
        optional: true,
        selected: true,
        description: "",
        SKU: ""
    }
]

const skeleton_proposal : IProposal = {
    id: "",
    deal_id: "",
    company_name: "",
    country: "",
    client_number: "",
    products: test_products,
    company_description: "",
    proposal_brief: "",
    delivery_date: "7",
    createdAt: 0,
    expiryDate: 10000,
    member_upsell: false,
    price: 0,
    client_name: "",
    already_member: false,
    membership_price: 99,
    status: "",
    value: 0,
    customer_id: "",
    creator: "",
    lawyer_notes: ""
}

const defaultState = {
    proposal: skeleton_proposal,
    loading: true,
    setLoading: (value: boolean) => {},
    getProposal: () => {},
    getBrief: () => {},
    setProposal: () => false,
    setProductSelected: () => {},
    total: 0,
    totalNoDiscount: 0,
    found: false,
    getPrice: (product : IProduct) => 0,
    gst: 0,
    gstTotal: 0,
    discountPrice: 0,
    discounted: false,
    expired: false,
    expiryDate: new Date(),
    paidAt: new Date(),
    checkout: false,
    setCheckout: () => {},
    products: [],
    optionalProducts: [],
    govProducts: [],
    selectedProducts: [],
    memberPrice: 99,
    setMemberUpsell: (value: boolean) => {},
    memberDiscount: 0,
    memberPopup: false,
    setMemberPopup: (value: boolean) => {},
    handleDetailsChange: (prop: string) => {},
    handlePhoneChange: (newValue: string) => {},
    details: {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        post: "",
        country: "",
        company_number: "",
        phone_number: ""
    },
    payment_completed: () => {},
    paymentLoading: false,
    setPaymentLoading: (value: boolean) => {},
    paymentSuccess: false,
    setPaymentSuccess: (value: boolean) => {},
    taxType: "GST",
    currency: "$",
    express: false,
    individual: false,
    setIndividual: (value: boolean) => {},
    noCompanyNumber: false,
    setNoCompanyNumber: (value: boolean) => {},
    manualAddress: false,
    setManualAddress: (value: boolean) => {},
    skipBooking: false,
    hideBookingAlert: false
}


const ProposalContext = createContext<IProposalContext>(defaultState);


type Props = {
    children?: React.ReactNode
};



export const ProposalProvider : FC<Props> = ({ children }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [found, setFound] = useState(false);
    const [proposal, setProposal] = useState<IProposal>(skeleton_proposal);

    const [memberPopup, setMemberPopup] = useState(false);

    const [checkout, setCheckout] = useState(false);
    const [individual, setIndividual] = useState(false);

    //No Company Number
    const [noCompanyNumber, setNoCompanyNumber] = useState(false);

    const [paymentLoading, setPaymentLoading] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const [manualAddress, setManualAddress] = useState(false);

    const [details, setDetails] = useState<DetailsInput>({
        first_name: proposal.client_name ? proposal.client_name.split(' ').slice(0, -1).join(" ") : "",
        last_name: proposal.client_name ? proposal.client_name.split(' ').slice(-1).join(" ") : "",
        email: "",
        company_name: proposal.company_name ? proposal.company_name : "",
        address_line_1: "",
        address_line_2: "",
        city: "",
        state: "",
        post: "",
        country: "",
        company_number: proposal.company_number ? proposal.company_number : "",
        phone_number: ""
    });

    const [express, setExpress] = useState(false);

    const handleDetailsChange = (prop : string) => (event : React.ChangeEvent<HTMLInputElement>) => {
        setDetails({ ...details, [prop]: event.target.value });
    };

    const handlePhoneChange = (newValue : string) => {
        setDetails({ ...details, phone_number: newValue});
    }
    
    const getProposal = useCallback(
        async (id: string) => {
        try {
            //await new Promise(resolve => setTimeout(resolve, 2000));
            await getProposalAPI(id).then(async (response) => {
                //console.log(response, "proposal")
                if(response.success === false){
                    setFound(false)
                    setLoading(false);
                }else{
                    await setProposal(setupProposal(response));
                    setFound(true)
                }

            }).catch((e) => {
                setFound(false)
                setLoading(false);
                console.error(e);
            });

        } catch (e) {
            setFound(false)
            console.error(e);
        }

    }, [])


    const getBrief = useCallback(
        async (id: string) => {
        try {
            //await new Promise(resolve => setTimeout(resolve, 2000));
            await getBriefAPI(id).then((response) => {
                //console.log(response, "brief")
                if(response.success === false){
                    setFound(false)
                    setLoading(false);
                }else{
                    setProposal(setupProposal(response));
                    setFound(true)
                }

            }).catch((e) => {
                setFound(false)
                setLoading(false);
                console.error(e);
            });

        } catch (e) {
            setFound(false)
            console.error(e);
        }

    }, [])

    // SET THIS AS STATE AND DONT SWITCH JUST CHANGE VALUE IN PRICE TO BE EXPRESS IF TRUE
    var original_delivery_date = "7";
    function setupProposal(proposal : IProposal){
        //console.log(proposal.delivery_date.toString())
        proposal.signed = false;
        for(let i = 0; i < proposal.products.length; i++){

            if(proposal.products[i].optional){
                if(proposal.preselected_packages){
                    proposal.products[i].selected = true;
                } else {
                    proposal.products[i].selected = false;
                }
            } else {
                proposal.products[i].selected = true;
            }

            if(proposal.products[i].government_fee){
                let link = proposal.products.findIndex(x => x.name === proposal.products[i].link);

                if(link !== null){
                    let link_item = {...proposal.products[link]};
                    if(link_item.optional){
                        if(proposal.preselected_packages){
                            proposal.products[i].selected = true;
                        } else {
                            proposal.products[i].selected = false;
                        }
                    } else {
                        proposal.products[i].selected = true;
                    }
                }
            }

            if(proposal.products[i].SKU === "Z0003" || proposal.products[i].SKU === "EWZ0003"){

                if(proposal.products[i].selected === true){
                    setExpress(true)
                }
                
            } 

        }



  


        setDetails({ ...details, 
            first_name: proposal.client_name ? proposal.client_name.split(' ').slice(0, -1).join(" ") : "",
            last_name: proposal.client_name ? proposal.client_name.split(' ').slice(-1).join(" ") : "",
            company_name: proposal.company_name ? proposal.company_name : "",
            company_number: proposal.company_number ? proposal.company_number : "",
        });
        
        if((proposal.add_membership || proposal.already_member) && proposal.status !== "PAID"){
            let products : IProduct[] = proposal.products;
            for(let i = 0; i < products.length; i++){
     
                if(!products[i].government_fee){

                    //DISCOUNT ALREADY EXIST
                    if(products[i].discount){
    
                        products[i].discount! += products[i].price * (proposal.membership_discount_percentage! / 100);
                        
                    } 
                    //NO DISCOUNT
                    else {
                        products[i].discount = products[i].price * (proposal.membership_discount_percentage! / 100);
                    }
                }
                
            }
        
            proposal.products = products;

            if(!proposal.already_member){
                proposal.member_upsell = true;
            }

        }

        if(proposal.status === "PAID" && proposal.purchased_membership){
            proposal.member_upsell = true;
        }

            

        details_form.setFieldValue("first_name", proposal.client_name ? proposal.client_name.split(' ').slice(0, -1).join(" ") : "");
        details_form.setFieldValue("last_name", proposal.client_name ? proposal.client_name.split(' ').slice(-1).join(" ") : "");
        details_form.setFieldValue("email", proposal.email ? proposal.email : "");
        details_form.setFieldValue("phone_number", proposal.phone ? proposal.phone : "");
        details_form.setFieldValue("company_name", proposal.company_name ? proposal.company_name : "");
        details_form.setFieldValue("company_number", proposal.company_number ? proposal.company_number : "");
        details_form.setFieldValue("address_search", proposal.formatted_address ? proposal.formatted_address : "");
        return proposal;
    }

    const tax : number = useMemo(() => {

        if(found){
            if(proposal.country === "AU"){
                return 0.1;
            }
            else if(proposal.country === "UK"){
                return 0.2;
            }

        }

        return 0.1;
    }, [proposal, found]);



    const total = useMemo(() => {
        var total = 0;
        let products : IProduct[] = proposal.products;
        if(found){
            for(var i = 0; i < products.length; i++){
                if(products[i].selected){
    
                    total += getPrice(products[i]);
                }
            }
        }

        if(proposal.member_upsell){
            total += proposal.membership_price!;
        }

        return total;
    } , [proposal, found]);

    const totalNoDiscount = useMemo(() => {
        var total = 0;
        let products : IProduct[] = proposal.products;
        if(found){
            for(var i = 0; i < products.length; i++){
                if(products[i].selected){
    
                    total += getPriceNoDiscount(products[i]);
                }
            }
        }

        return total;
    } , [proposal, found]);


    //TOTAL PRICE OF GST
    const gst = useMemo(() => {
        let total = 0;
        let products : IProduct[] = proposal.products;

        if(found){
            for(var i = 0; i < products.length; i++){
                if(products[i].selected){
                    if(!products[i].government_fee && !products[i].gst_free){
                        total += getPrice(products[i]);
                    }

                }
            }

            if(proposal.member_upsell){
                total += (proposal.membership_price!);
            }

            return total * tax;
        }

        return total;
    }, [proposal, found, tax]);

    //TOTAL PRICE OF PROPOSAL INCLUDING GST
    const gstTotal = useMemo(() => {
        let total = 0;
        let products : IProduct[] = proposal.products;

        if(found){
            for(var i = 0; i < products.length; i++){
                if(products[i].selected){
                    if(products[i].government_fee){
                        total += getPrice(products[i]);
                    }else{
                        if(products[i].gst_free){
                            total += getPrice(products[i])
                        }else{
                            total += getPrice(products[i]) * (1 + tax);
                        }
                    }

                }
            }

            if(proposal.member_upsell){
                total += (proposal.membership_price! * (1 + tax));
            }
        }


        return total;
    }, [proposal, found, tax]);

    


    const discountPrice = useMemo(() => {
        let total = 0;
        let products : IProduct[] = proposal.products;

        if(found){
            for(var i = 0; i < products.length; i++){
                if(products[i].selected){
                    if(!products[i].government_fee && products[i].discount){
                        total += products[i].discount!;
                    }
                }
            }

        }


        return total;
    }, [proposal, found]);

    const memberDiscount : number = useMemo(() => {
        let total = 0;
        let products : IProduct[] = proposal.products;

        if(found){
            for(var i = 0; i < products.length; i++){
                if(products[i].selected){

                    if(!products[i].government_fee){
                        total += (products[i].price * (proposal.membership_discount_percentage! / 100));
                    }
                }
            }

        }

        return total;


    }, [proposal, found]);
    
    const discounted : boolean = useMemo(() => {
        if(discountPrice > 0){
            return true;
        }
        return false;
    }, [discountPrice]);


    const expired : boolean = useMemo(() => {
        let now = new Date();

        if(found){
            //console.log(proposal.company_number)
            let expiry_date = new Date(proposal.expiryDate);
            //if expiry date has passed
            if(expiry_date.getTime() < now.getTime() && proposal.status !== "PAID"){
                return true;
            }
        }

        return false;
    }, [proposal, found]);

    const expiryDate = useMemo(() => {
        let date = new Date();

        if(found){
            let date = new Date(proposal.expiryDate);
            return date;
        }
        return date;
    }, [proposal, found]);

    const paidAt = useMemo(() => {
        let date = new Date();

        if(found && proposal.paidAt){
            let date = new Date(proposal.paidAt);
            return date;
        }
        return date;
    }, [proposal, found]);

    // GETS THE PRICE OF A PRODUCT
    function getPrice(product : IProduct ) : number {
        if(product.discount){
            return (product.price - product.discount);
        } else {
            return (product.price);
        }
    }

    function getPriceNoDiscount(product : IProduct ) : number {
        return (product.price);
    }

    function setProductSelected(name: string, selected: boolean){
        var s = [...proposal.products];
        var f = s.findIndex(x => x.name === name);
        let item = {...s[f]};


        let link = s.findIndex(x => x.link === item.name);

        if(link !== null){
            let link_item = {...s[link]};
            link_item.selected = selected;
            s[link] = link_item;
        }

        item.selected = selected;
        s[f] = item;

        if(item.SKU === "Z0003" || item.SKU === "EWZ0003"){

            const delivery_date = selected ? proposal.express_delivery_date! : original_delivery_date;
            //console.log("selected", delivery_date, selected, proposal.express_delivery_date, proposal.delivery_date)
            //setProposal({ ...proposal, delivery_date: delivery_date, products: s})
            setExpress(selected);
            
        } else {
            setProposal({ ...proposal, products: s})
        }

        setProposal({ ...proposal, products: s})

    }

    const products : IProduct[] =  useMemo(() => {
        let products : IProduct[] = [];

        if(found){
            products = proposal.products.filter((product : IProduct) => !product.government_fee && !product.optional);
        }

        return products;

    }, [proposal.products, found]);

    const optionalProducts : IProduct[] =  useMemo(() => {

        let products : IProduct[] = [];

        if(found){
            products = proposal.products.filter((product : IProduct) => !product.government_fee && product.optional);
        }

        return products;

    }, [proposal.products, found]);

    const govProducts : IProduct[] =  useMemo(() => {
        let products : IProduct[] = [];

        if(found){
            products = proposal.products.filter((product : IProduct) => product.government_fee);
        }

        return products;
    }, [proposal.products, found]);

    const selectedProducts : IProduct[] =  useMemo(() => {
        let products : IProduct[] = [];

        if(found){
            products = proposal.products.filter((product : IProduct) => product.selected);
        }

        return products;
    }, [proposal.products, found]);

    const taxType : string =  useMemo(() => {
        let taxType = "GST"

        if(found){
            switch(proposal.country){
                case "AU":
                    taxType = "GST";
                    break;
                case "UK":
                    taxType = "VAT";
                    break;
                default:
                    taxType = "GST";
                    break;
            }
        }

        return taxType;
    }, [proposal, found]);

    const currency : string =  useMemo(() => {
        let currency = "AUD"

        if(found){
            switch(proposal.country){
                case "AU":
                    currency = "$";
                    break;
                case "UK":
                    currency = "£";
                    break;
                default:
                    currency = "$";
                    break;
            }
        }
        return currency;
    }, [proposal, found]);

    const skipBooking : boolean =  useMemo(() => {
        let skip = false;

        if(found){
            let createdAt = new Date(proposal.createdAt);

            // HAD TO DO THIS SO THAT THE BOOKING OPTION DOESNT SHOW ON LEGACY PROPOSALS ANYTHING CREATED BEFORE MARCH 1st
            let cutOver = new Date("2023-03-01")
            //console.log("createdAt", createdAt.getTime(), cutOver.getTime(), createdAt.getTime() < cutOver.getTime())
            if(proposal.manual_allocation || total > 5000 || express || createdAt.getTime() < cutOver.getTime()){
                return true;
            }
        }
        
        return skip;
    }, [proposal.products, found, express, proposal.createdAt]);

    
    const hideBookingAlert : boolean =  useMemo(() => {
        let skip = false;

        if(found){
            let createdAt = new Date(proposal.createdAt);

            // HAD TO DO THIS SO THAT THE BOOKING OPTION DOESNT SHOW ON LEGACY PROPOSALS ANYTHING CREATED BEFORE BOOKING CUT OVER
            let cutOver = new Date("2023-04-11")
            //console.log("createdAt", createdAt.getTime(), cutOver.getTime(), createdAt.getTime() < cutOver.getTime())
            if(proposal.manual_allocation || total > 5000 || express || createdAt.getTime() < cutOver.getTime()){
                return true;
            }
        }
        
        return skip;
    }, [proposal.products, found, express, proposal.createdAt]);

    function setMemberUpsell(value : boolean){
        if(value){
            setMemberPopup(false)
            let products : IProduct[] = proposal.products;
            for(let i = 0; i < products.length; i++){

                    if(!products[i].government_fee){

                        //DISCOUNT ALREADY EXIST
                        if(products[i].discount){
        
                            products[i].discount! += products[i].price * (proposal.membership_discount_percentage! / 100);
                            
                        } 
                        //NO DISCOUNT
                        else {
                            products[i].discount = products[i].price * (proposal.membership_discount_percentage! / 100);
                        }
                    }
                }
            
        }
        else{
            //console.log("Removed Membership")
            setMemberPopup(true)
            let products : IProduct[] = proposal.products;
            for(let i = 0; i < products.length; i++){
 
                    if(!products[i].government_fee){

                        //DISCOUNT ALREADY EXIST
                        if(products[i].discount){
                            if(products[i].discount! > 0){
                                products[i].discount! -= products[i].price * (proposal.membership_discount_percentage! / 100);
                            } else {
                                delete products[i].discount;
                            }
                        } 
                        //NO DISCOUNT
                        else {
                            delete products[i].discount;
                        }
                    }
                
            }
        }
        
        setProposal({ ...proposal, member_upsell: !proposal.member_upsell})
    }


    //DETAILS
    const validationSchema = yup.object({
        first_name: yup
            .string()
            .required('First name is required'),
        last_name: yup
            .string()
            .required('Last name is required'),
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        company_name: !individual ?
            yup
            .string()
            .required('Company Name is required') 
            :
            yup
            .string()
            .notRequired()
            ,
        address_search: !manualAddress ? 
            yup
            .string()
            .required('Address is required or manually enter address') 
            :
            yup
            .string()
            .notRequired(),
        company_number: proposal.country === "AU" ? 
            yup
            .string()
            .notRequired()
            .min(9, 'ABN or ACN must be between 9 and 11 Digits')
            .max(11, 'ABN or ACN must be between 9 and 11 Digits') : 
            yup
            .string()
            .notRequired()
            .min(6, 'Company number must be between 6 and 10 Digits')
            .max(10, 'Company number must be between 6 and 10 Digits') ,
        additional_company_number: 
            yup
            .string()
            .notRequired(),
        phone_number: yup
            .string()
            .required('Phone Number is required'),
        formatted_address: yup
            .string()
            .notRequired(),   
        address_line_1: yup
            .string()
            .required('Address is required'),
        city: yup
            .string()
            .required(proposal.country === "AU" ? "City is required" : "Town / City is required"),
        state: yup
            .string()
            .required(proposal.country === "AU" ? "State is required" : "County / State is required"),
        postcode: yup
            .string()
            .required('Postcode is required'),
        country: yup
            .string()
            .required('Country is required'),
      });

    const details_form = useFormik({
        initialValues: {
            first_name: details.first_name,
            last_name: details.last_name,
            email: "",
            company_name: details.company_name,
            company_number: details.company_number,
            additional_company_number: "",
            phone_number: "",
            address_search: "",
            place_id: "",
            formatted_address: "",
            address_line_1: "",
            address_line_2: "",
            city: "",
            postcode: "",
            country: "",
            state: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            //console.log(values)
        },
    });

    function roundToWhole(value : number) {
        let a = Math.round(value * 100 ) / 100;
        let b = a.toString().length;
        let c = 17 - b - 2;
        return Math.round(value * 100 + 0.1 ** c ) / 100;
    }

    //PROPOSAL PAYMENT
    const payment_completed = (paymentIntent : PaymentIntent) => {

        //console.log(details_form.values)

        let stripe_value = parseFloat(gstTotal.toFixed(2)) * 100;

        let completed_proposal : ICompletedPayment = {
            id: proposal.id,
            membership: proposal.member_upsell ? true : false,
            client_number: proposal.client_number,
            company_description: proposal.company_description,
            additional_notes: proposal.additional_notes,
            country: proposal.country,
            createdAt: proposal.createdAt,
            deal_id: proposal.deal_id,
            delivery_date: proposal.delivery_date,
            expiryDate: proposal.expiryDate,
            proposal_brief: proposal.proposal_brief,
            status: proposal.status,
            member_upsell: proposal.member_upsell,
            membership_price: proposal.membership_price ? proposal.membership_price : 0,
            membership_discount: proposal.membership_discount,
            membership_discount_percentage: proposal.membership_discount_percentage,
            already_member: proposal.already_member,
            value: parseFloat(gstTotal.toFixed(2)),
            stripe_value: roundToWhole(stripe_value),
            products: selectedProducts,
            payment_intent: paymentIntent,
            company_name: individual ? details_form.values.first_name + " " + details_form.values.last_name : details_form.values.company_name,
            client_name: details_form.values.first_name + " " + details_form.values.last_name,
            company_number: details_form.values.company_number,
            additional_company_number: details_form.values.additional_company_number,
            formatted_address: details_form.values.formatted_address,
            address_line_1: details_form.values.address_line_1,
            address_line_2: details_form.values.address_line_2,
            city: details_form.values.city,
            state: details_form.values.state,
            postcode: details_form.values.postcode,
            country_code: details_form.values.country,
            phone_number: details_form.values.phone_number,
            email: details_form.values.email,
            customer_id: proposal.customer_id,
            express: express,
            express_delivery_date: proposal.express_delivery_date
        }
 

        //console.log(completed_proposal);

        completePayment(completed_proposal).then((response) => {
            //console.log(response)
            if(response.success === true){
                setPaymentLoading(false);

                setPaymentSuccess(true);

            }
        })
        .catch((error) => {
            console.error(error)
        });


        
    }

    return (
        <ProposalContext.Provider 
            value={{
                loading,
                setLoading,
                proposal,
                getProposal,
                getBrief,
                setProposal,
                setProductSelected,
                total,
                totalNoDiscount,
                found,
                getPrice,
                gst,
                gstTotal,
                discountPrice,
                discounted,
                expired,
                expiryDate,
                paidAt,
                checkout,
                setCheckout,
                products,
                optionalProducts,
                govProducts,
                selectedProducts,
                memberPrice: proposal.membership_price!,
                setMemberUpsell,
                memberDiscount,
                memberPopup,
                setMemberPopup,
                handleDetailsChange,
                handlePhoneChange,
                details,
                details_form,
                payment_completed,
                paymentLoading,
                setPaymentLoading,
                paymentSuccess,
                setPaymentSuccess,
                taxType,
                currency,
                express,
                individual,
                setIndividual,
                noCompanyNumber,
                setNoCompanyNumber,
                manualAddress,
                setManualAddress,
                skipBooking,
                hideBookingAlert
            }}
        >

            {children}
      
        </ProposalContext.Provider>
    );
  }


export function useProposal() {
    const context = useContext(ProposalContext);
    if (!context) {
        throw new Error("useLoading must be used within ProposalProvider");
    }
    return context;
}