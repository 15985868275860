import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProposal } from '../providers/ProposalProvider';
import { getAuth, signInWithPopup, GoogleAuthProvider, setPersistence, signOut, browserLocalPersistence , onAuthStateChanged   } from "firebase/auth";

import styled from 'styled-components'

//COMPONENTS
import Header from './Proposal/Header';
import Error from './Proposal/Error';
import Price from './Proposal/Price';
import BriefSignIn, { GoogleButton } from './Proposal/BriefSignIn';
import LawyerBrief from './Proposal/LawyerBrief';



const ProposalContainer = styled.div`
    margin: 0 auto;

    @media (max-width: 768px) {
        height auto;
    }
`


function Brief(){


    const [user, setUser] = useState<any>(null);
    
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence)


    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          setUser(user);
          // ...
        } else {
          // User is signed out
          // ...

        }
      });

    const signInUser : Function = () => signInWithPopup(auth, provider)
        .then((result) => {

            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);

            
            //const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            setUser(user);

            //console.log(user)
            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...

            console.error(error)
    });

    /*
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            // ...
            setUser(user)
            console.log("has user")
        } else {
            // User is signed out
            // ...
            console.log("signed out")
            setUser(null)
        }
    });*/

    const signOutUser : Function = () => signOut(auth).then(() => {
        // Sign-out successful.

        setUser(null)
      }).catch((error) => {
        // An error happened.
        console.error(error)
      });


    return user ? <SignedIn signOut={signOutUser} user={user} /> :
    (
        <ProposalContainer >
            <Header loading={false}/>

            <BriefSignIn signIn={signInUser} />
        </ProposalContainer>
    )
}




function SignedIn({signOut, user } : {signOut : Function, user : any}) {


    let { id } = useParams();
    const { getBrief, loading, found, setLoading} = useProposal();

    useEffect(() => {

        async function wait(){

            await getBrief(id!)
            setLoading(false)

            //console.log(proposal)
        }

        wait();
    }, [id, getBrief]);

    return (
        <ProposalContainer >
            <Header loading={loading}/>

            {!loading && found &&
            <>

                <LawyerBrief signOut={signOut} user={user} />
                <Price complete />
            </>
            }
            {!loading && !found && <Error />}
        </ProposalContainer>
    )

}



export default Brief;