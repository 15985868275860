import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useProposal } from '../providers/ProposalProvider';

import styled from 'styled-components'

//COMPONENTS
import Header from './Proposal/Header';
import Error from './Proposal/Error';
import CompleteBody from './Proposal/CompleteBody';
import Price from './Proposal/Price';

const ProposalContainer = styled.div`
    margin: 0 auto;

    @media (max-width: 768px) {
        height auto;
    }
`


function Complete(){
    let { id } = useParams();
    const { getProposal, loading, found, paymentLoading, proposal, setLoading} = useProposal();
    


    useEffect(() => {

        async function wait(){

            await getProposal(id!)
            setLoading(false)

            //console.log(proposal)
        }

        wait();
    }, [id, getProposal]);


    return (

            <ProposalContainer >
                <Header loading={loading}/>
                {!loading && found && proposal.status === "PAID" &&
                <>
                    <CompleteBody />
                    <Price complete />
                </>
                }
                {!loading && !found && !paymentLoading && <Error />}
                {!loading && found && proposal.status !== "PAID" && <Error />}
            </ProposalContainer>

    )
}

export default Complete;