import styled from 'styled-components'

import QuoteTable from './QuoteTable';
import { useProposal } from '../../providers/ProposalProvider';
import AddonsTable from './AddonsTable';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { MdOutlineHelp } from 'react-icons/md';
import AdditionalNotes from './AdditionalNotes';
import GovTable from './GovTable';

import { useReactToPrint } from 'react-to-print';

import parse from 'html-react-parser';
import Paid from './Paid';
import Button from '@mui/material/Button';
import { BsPrinter } from 'react-icons/bs';
import { useRef } from 'react';
import { Group, Stack, Divider } from '@mantine/core';

import SprintlawLogo from '../../img/SprintlawLogo.svg';
import FAQ from './FAQ';
import MembershipTable from './MembershipTable';
import NotBooked from './NotBooked';
import Booked from './Booked';

const BodyContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 100%;


    @media (min-width: 768px) {
        width: 720px;
    }

    @media (min-width: 992px) {
        width: 960px;
    }

    @media (min-width: 1300px) {
        width: 1200px;
    }

`

const ProposalBody = styled.div`
    width: 90%;
    min-height: 85vh;
    margin: 40px auto 0;
`

const ClientName = styled.h1`
    width: 100%;
    text-align: left;
    padding: 50px 0 10px;
    font-size: 18px;
`
const Brief = styled.p`
    width: 100%;
    text-align: left;
    padding: 10px 0;
    font-size: 16px;
`

const TableHeader = styled.h1`
    padding: 40px 0;
    font-size: 23px;
    color: #17C0B9;

    width: 100%;
    text-align: center;
    font-weight: 600;
`
interface TooltipProps {
    label : string
}


const HeaderTooltip = styled(IconTooltip)`
    float: right !important;
    height: 40px;
    width: 40px;
`
const Help = styled(MdOutlineHelp)`
    color: #17C0B9;
    width: 30px;
    height: 30px;

`

const TooltipText = styled.h1`
    font-size: 20px;
`

const Print = styled(Button)`
    width: 100px;
    margin: 20px auto !important;
    float:right;

    font-family: 'Montserrat', sans-serif !important;
    border-color: #17C0B9 !important;
    color: #17C0B9 !important;
`


const PriceContainer = styled.div`
    margin: 80px 0 0 0;
    width: 100%;
    min-height: fit-content;
    position: static;
    bottom: 0;
    left: 0;

    background-color: #F3F3F3;

    @media (max-width: 768px) {
        position: relative;
    }
`

const PriceBanner = styled.div`
    width: 100%;
    height: 10px;
    background-color: #17C0B9;
`
const PriceSummary = styled.div`
    padding: 20px;
    width: 30%;
    height: 100%;

    @media (max-width: 768px) {
        width: 100%;

    }
`

const ValueContainer = styled.div`
`

const PriceText = styled.h2`
    float: left;
    font-weight: 600;
`
const PriceValue = styled.h2`
    float: right;
    font-weight: 600;
`

const PriceGroup = styled(Group)`
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const HeaderDiv = styled.div`
    position: absolute;
    height: 150px;
    width: 100%;
    background: #17C0B9;
    transition: all .5s ease-in-out;
    z-index: 1;

    left: 0;
    top: 0;



    @media (max-width: 768px) {
        width: 100vw;
    }
`

const HeaderPhantom = styled.div`
    height: 150px;
`

const Logo = styled.img`
    height: 50px;
    width: 200px;
    margin: 40px auto;
`

const TitleContainer = styled.div`
    height: 100%;
    width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 300px;
        margin: 0 auto;
    }
`

function IconTooltip(props : TooltipProps){
    const { label } = props;

    return (
        <Tooltip title={<TooltipText>{label}</TooltipText>}
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        >
            <IconButton><Help /></IconButton>
        </Tooltip>
    )
}

const PrintContainer = styled.div`
    display: none;

    @media print {
        display: unset;
    }
`

const HidePrint = styled.div`
    display: unset;

    @media print {
        display: none;
    }
`




function CompleteBody() {
    const { proposal, optionalProducts, govProducts, totalNoDiscount, discounted, memberPrice, discountPrice, gst, gstTotal, currency, taxType, expired, hideBookingAlert} = useProposal();


    const componentRef = useRef(null);

    const GovText = `These are the Government Fees & Disbursements associated with some of the fixed fee packages listed above. No ${taxType} is payable on these fees.${proposal.member_upsell ? " Member discounts do not apply to government fees." : ""}`

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        copyStyles: true

    });

    return (
        <BodyContainer ref={componentRef} >

            <ProposalBody>
                <PrintContainer>
                    <HeaderDiv >
                        <TitleContainer>
                            <Logo src={SprintlawLogo} alt="Sprintlaw Logo" />
                        </TitleContainer>
                    </HeaderDiv>
                    <HeaderPhantom />
                </PrintContainer>
                <Print onClick={handlePrint} variant="outlined" startIcon={<BsPrinter />} >
                    Print
                </Print>
                
                {!proposal.booked && !hideBookingAlert && !expired && <NotBooked />}
                {proposal.booked && !hideBookingAlert && <Booked />}
                {proposal.paidAt && <Paid />}

                <ClientName>Dear {proposal.client_name}</ClientName>
                <Brief>{parse(proposal.proposal_brief)}</Brief>

                <TableHeader>Your Packages</TableHeader>

                <QuoteTable complete={true} />


                {optionalProducts.length > 0 &&  
                <>
                    <TableHeader>Selected Addons</TableHeader>
                    <AddonsTable complete={true} />
                </>
                }

                {govProducts.length > 0 &&
                <>
                    <TableHeader>Government Fees & Disbursements <HeaderTooltip label={GovText}/></TableHeader>
                    <GovTable complete={true} />
                </>
                
                }

                {proposal.purchased_membership &&
                <>
                    <TableHeader>Sprintlaw Membership</TableHeader>
                    <MembershipTable complete={true} />
                </>
                
                }

                <HidePrint>
                    <TableHeader>FAQ</TableHeader>
                    <FAQ />
                </HidePrint>

                {proposal.additional_notes && 
                <>
                    <TableHeader>Additional Notes</TableHeader>
                    <AdditionalNotes />
                </>}

               

            </ProposalBody>
            <PrintContainer>
                    <PriceContainer >
                        <PriceBanner />
                        <PriceGroup position="center">
                            <PriceSummary>
                                <Stack>
                                    <ValueContainer>
                                        <PriceText>Subtotal</PriceText> <PriceValue>{currency}{totalNoDiscount.toFixed(2)}</PriceValue>
                                    </ValueContainer>
                                    { proposal.member_upsell && 
                                    <ValueContainer>
                                        <PriceText>Membership Fee</PriceText> <PriceValue>{currency}{memberPrice.toFixed(2)}</PriceValue>
                                    </ValueContainer>
                                    }
                                    { discounted &&
                                    <ValueContainer>
                                        <PriceText>Discount</PriceText> <PriceValue>-{currency}{discountPrice.toFixed(2)}</PriceValue>
                                    </ValueContainer>
                                    }
                                    <ValueContainer>
                                        <PriceText>{taxType}</PriceText> <PriceValue>{currency}{gst.toFixed(2)}</PriceValue>
                                    </ValueContainer>
                                    <Divider />
                                    <ValueContainer>
                                        <PriceText>Grand Total</PriceText> <PriceValue>{currency}{gstTotal.toFixed(2)}</PriceValue>
                                    </ValueContainer>
                                </Stack>
                            </PriceSummary>  
                        </PriceGroup>
                    </PriceContainer>
                </PrintContainer>
        </BodyContainer>
    )
}

export default CompleteBody;

