import styled from "styled-components";
import { useProposal } from '../../../providers/ProposalProvider';
import { Button, Divider } from '@mantine/core';
import HelpTooltip from "../HelpTooltip";
import { Link } from "react-router-dom";

const CartContainer = styled.div`
    width: 100%;
    position: relative;
`

const Header = styled.h2`
    padding: 20px 20px 5px 0;
    color: #697386;
    font-size: 16px;

    @media (max-width: 768px) {
        display: none;
    }
`

const Price = styled.p`
    padding: 5px 20px 0 0;
    color: #1A1F36;
    font-size: 36px;
    font-weight: 700;

    line-height: 44px;

    @media (max-width: 768px) {
        display: none;
    }
`

const Item = styled.div`
    padding: 20px 0 0 0;
    width: 100%;
    min-height: 48px;
    height: fit-content;
    display: inline-block;
`

const MemberItem = styled.div`
    padding: 30px 0 20px 0;
    width: 100%;
    min-height: 48px;
    height: fit-content;
`


interface IItemPrice {
    $discount: boolean
}

const ItemName = styled.p`
    font-weight: 700;
    padding: 5px 0 ;
`

const ItemPrice = styled.p<IItemPrice>`
    float: left;
    text-align: left;
    text-decoration: ${props => props.$discount ? "line-through" : "none"};
    width: 20%;
    display: table-cell;
    min-height: 19px;
`

const ItemDiscount = styled.p`
    margin-left: 20px;
    color: #17C0B9;
    font-weight: 700;
    text-align: left;
    text-decoration: none !important;
    width: 50%;
    display: table-cell;
`

const TotalTable = styled.div`
    padding: 20px 0 20px 0;
`

const TotalText = styled.p`
    padding: 5px;

    margin: 0;
`


const TotalValue = styled.p`
    float: right;
`


function Cart(){
    const { gstTotal, gst, totalNoDiscount, selectedProducts, proposal, memberPrice, discounted, discountPrice, setCheckout, paymentLoading, currency, taxType} = useProposal();

    const DiscountHelper = "Discounts don’t apply to government fees or disbursements."

    return (
        <CartContainer>
            <Header>Amount Due</Header>
            <Price>{currency}{gstTotal.toFixed(2)}</Price>
            {selectedProducts.map((product) => (
                <Item key={product.name}><ItemName>{product.name}</ItemName> <ItemPrice $discount={product.discount! > 0}>{currency}{product.price}</ItemPrice>{product.discount! > 0 && <ItemDiscount>{currency}{product.price - product.discount!}</ItemDiscount>}</Item>
            ))}
            {proposal.member_upsell && (
                
                    <MemberItem><ItemName>Sprintlaw Membership</ItemName> <ItemPrice $discount={false}>{currency}{memberPrice}</ItemPrice></MemberItem>
            )}
                          
            <TotalTable>
                <TotalText>Subtotal<TotalValue>${totalNoDiscount.toFixed(2)}</TotalValue></TotalText> 
                <Divider />
                {discounted && (
                    <>
                        <TotalText>Fixed Fee Discount<HelpTooltip label={DiscountHelper} aria={DiscountHelper}/><TotalValue>{currency}{discountPrice.toFixed(2)}</TotalValue></TotalText> 
                        <Divider />
                    </>
                )}
                <TotalText>{taxType}<TotalValue>{currency}{gst.toFixed(2)}</TotalValue></TotalText> 
                <Divider />
                <TotalText><b>Total Due</b> <TotalValue>{currency}{gstTotal.toFixed(2)}</TotalValue></TotalText> 
            </TotalTable>

            <Link to={"/"+proposal.id}
            style={{
                textDecoration: "none",
            }}
            >
                <Button fullWidth color="gray" 
                    disabled={paymentLoading}
                    style={{
                        height: "50px", 
                        fontFamily: "Montserrat !important",
                        fontSize: "16px",
                        fontWeight: 400,
                        textDecoration: "none",
                    }}>Back</Button>
            </Link>
   
        </CartContainer>
    )
}

export default Cart;