import React, { useState } from 'react';
import { IProduct } from "../../types/Proposal";

import useMediaQuery from '@mui/material/useMediaQuery';

import { 
    TableRow,
    TableBody,
    IconButton,
    Collapse,
    Checkbox
 } from '@mui/material'
 
import { useProposal } from '../../providers/ProposalProvider';

import { 
    ProposalRow ,
    ProposalHead,
    ProposalCell,
    BoldProposalCell,
    Description,
    Expanded,
    Collapsed,
    ProposalTable,
    ProposalTableContainer,
    HeaderCell,
    ExpandCell,
    CollapsableRow,
    MobileCheckbox,
    ExpandHeaderCell,
    HiddenCell
} from './ProposalTable';

import parse from 'html-react-parser';
import styled from 'styled-components';

interface RowProps {
    product: IProduct
    complete?: boolean
}

interface IRowType {
    product: IProduct,
    open: boolean,
    setOpen: Function,
    complete?: boolean
}

function MobileRow(props : IRowType ){
    const { product, open, setOpen, complete } = props;
    const { currency } = useProposal();
    return (
        <ProposalRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <ExpandCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <Expanded /> : <Collapsed />}
                </IconButton>
            </ExpandCell>
            <BoldProposalCell component="th" scope="row">
                {product.name}
                <br />
                {currency}{product.price.toFixed(2)}
                <br />
            </BoldProposalCell>
            {!complete && 
            <ProposalCell>
                <MobileCheckbox      
                    checked={product.selected}
                    aria-label={product.name + " checkbox"}
                    disabled
                />
            </ProposalCell>
            }
        </ProposalRow>
    )
}

function DesktopRow(props : IRowType){

    const { product, open, setOpen, complete } = props;

    const { discounted, currency } = useProposal();
    
    return (
        <ProposalRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <ExpandCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <Expanded /> : <Collapsed />}
                </IconButton>
            </ExpandCell>
            <BoldProposalCell component="th" scope="row">
                {product.name}
            </BoldProposalCell>
            <ProposalCell colSpan={2}>
                {currency}{product.price.toFixed(2)}
            </ProposalCell>
            {discounted &&
            <HiddenCell colSpan={2}>
            </HiddenCell>
            }
            {!complete &&
            <ProposalCell>
                <Checkbox      
                    checked={product.selected}
                    aria-label={product.name + " checkbox"}
                    disabled
                />
            </ProposalCell>
            }
        </ProposalRow>
    )
}

const DescText = styled.p`
    line-height: 3em;

    @media (max-width: 768px) {
        line-height: 1.5em;
        margin: 10px 0;
    }
`

const DescPara = styled.p`
    margin: 20px 0;
`

const DescList = styled.ul`
    padding-left: 30px;
`

const DescItem = styled.li`
    padding: 5px;
`

function Row(props : RowProps){

    const mobile = useMediaQuery('(max-width:768px)');

    const { complete, product } = props;

    const [open, setOpen] = useState(complete ? true : false);

    const { proposal, currency, taxType } = useProposal();

    const membership_price = proposal.country === "AU" ? 799 : 399;
    const signatures_price = proposal.country === "AU" ? 1.45 : 1;

    //const discount = product.discount_type == "flat" ? "$" + product.discount!.toFixed(2) : product.discount!.toFixed(2) + "%";
    return(
        <React.Fragment>
            {mobile ? 
                <MobileRow product={product} open={open} setOpen={setOpen} complete={complete} />
                :
                <DesktopRow product={product} open={open} setOpen={setOpen} complete={complete} />
            }

            <CollapsableRow>
                <ProposalCell style={{ padding: 0, width: "100%" }} colSpan={7}>
                    <Collapse in={open} timeout="auto" aria-label={product.name + " scope"}>
                        <Description sx={{ margin: 1 }}>
                        <p>
                            <DescText>12 month subscription to the Sprintlaw Membership, including:</DescText>
                            <DescList>
                                <DescItem><b>Member Discount:</b> 10% discount on any fixed-fee legal services</DescItem>
                                <DescItem><b>Legal Helpline:</b> 30 min calls with our lawyers </DescItem>
                                <DescItem><b>Updates:</b> Free simple updates to documents we've drafted for your business</DescItem>
                                <DescItem><b>Law Bots:</b> Create free, simple legal docs yourself using our legal chat bots</DescItem>
                                <DescItem><b>eSignatures:</b> Access to our Sprintlaw Signatures tool ({currency}{signatures_price} per doc)</DescItem>
                            </DescList>
                            <DescPara>These features are accessible from our Sprintlaw Membership portal. Our team will email you your login details shortly.</DescPara>
                            <DescPara>There is no strict limit on the number of phone consultations or free updates you can request. However, we do have a <a href="https://pilotl.ink/r?i=sprintlaw&e=abrkqcrxj5vxgpp6fmauay5wpktwq7lzw7xgw2q3iuiuqzep2aekmjmcfmietp6b674fcskfnvagkx2lawcewjqqiraumw2koosjyir5sqsgxcra5ort72czyko4sbt2ra7erpybdm2ry" target="_blank" >fair use policy</a> in place and our ability to respond to your requests is dependent on our legal team’s capacity.</DescPara>
                            <DescPara>For more guidelines on what is included and the areas of law we can advise on, please visit the <a href="https://pilotl.ink/r?i=sprintlaw&e=abrkqcrxj5vxgpp6fmauay5wpktwq7lzw7xgw2q3iuiuqzep2aekmjmcfmietp6b674fcskfnvagkx2lawcewjqqiraumw2koosjyir5sqsgxcra4oudlgt7yyfhlzvkwq" target="_blank">membership FAQs</a>.</DescPara>
                            <p>The Sprintlaw Membership renews annually at {currency}{membership_price} + {taxType}. You can cancel at anytime by contacting our team. </p>
                        </p>
                        </Description>
                    </Collapse>
                </ProposalCell>
            </CollapsableRow>
        </React.Fragment>
    )
}

interface IGovTable {
    complete?: boolean
}




function MembershipTable( props : IGovTable ) {
    
    const { complete } = props;

    const { proposal, taxType, currency } = useProposal();

    const membership_price = proposal.country === "AU" ? 799 : 399;
    const signatures_price = proposal.country === "AU" ? 1.45 : 1;

    const membership : IProduct = {
        name: "Sprintlaw Membership",
        description: `
        <p>
            <p style="lineHeight: 3em">12 month subscription to the Sprintlaw Membership, including:</p>
            <ul>
                <li><b>Member Discount:</b> 10% discount on any fixed-fee legal services</li>
                <li><b>Legal Helpline:</b> 30 min calls with our lawyers </li>
                <li><b>Updates:</b> Free simple updates to documents we've drafted for your business</li>
                <li><b>Law Bots:</b> Create free, simple legal docs yourself using our legal chat bots</li>
                <li><b>eSignatures:</b> Access to our Sprintlaw Signatures tool (${currency}${signatures_price} per doc)</li>
            </ul>
            <p style="margin: 20px 0">These features are accessible from our Sprintlaw Membership portal. Our team will email you your login details shortly.</p>
            <p style="margin: 20px 0">There is no strict limit on the number of phone consultations or free updates you can request. However, we do have a <a href="https://pilotl.ink/r?i=sprintlaw&e=abrkqcrxj5vxgpp6fmauay5wpktwq7lzw7xgw2q3iuiuqzep2aekmjmcfmietp6b674fcskfnvagkx2lawcewjqqiraumw2koosjyir5sqsgxcra5ort72czyko4sbt2ra7erpybdm2ry" target="_blank" >fair use policy</a> in place and our ability to respond to your requests is dependent on our legal team’s capacity.</p>
            <p style="margin: 20px 0">For more guidelines on what is included and the areas of law we can advise on, please visit the <a href="https://pilotl.ink/r?i=sprintlaw&e=abrkqcrxj5vxgpp6fmauay5wpktwq7lzw7xgw2q3iuiuqzep2aekmjmcfmietp6b674fcskfnvagkx2lawcewjqqiraumw2koosjyir5sqsgxcra4oudlgt7yyfhlzvkwq" target="_blank">membership FAQs</a>.</p>
            <p>The Sprintlaw Membership renews annually at ${currency}${membership_price} + ${taxType}. You can cancel at anytime by contacting our team. </p>
        </p> `,
        price: proposal.membership_price!,
        selected: true,
        discount: 0,
        optional: false,
        SKU: ''
    }

    return(
        <ProposalTableContainer >
            <ProposalTable aria-label="collapsible table">
                <ProposalHead>
                    <TableRow>
                        <ExpandHeaderCell colSpan={1}  align="left"></ExpandHeaderCell>
                        <HeaderCell colSpan={1}  align="left">Description</HeaderCell>
                        <BoldProposalCell align="left" colSpan={2}>Price (ex {taxType})</BoldProposalCell>
                    </TableRow>
                </ProposalHead>
                <TableBody>
                    <Row key={membership.name} product={membership} complete={complete} />
                </TableBody>
            </ProposalTable>
        </ProposalTableContainer>
    )
}

export default MembershipTable;
