import styled from "styled-components";



import Payment from "./Proposal/Checkout/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions, Appearance } from "@stripe/stripe-js";

import { useEffect, useState } from "react";
import { setupPayment } from "../services/APIService";
import { useProposal } from "../providers/ProposalProvider";
import Cart from "./Proposal/Checkout/Cart";
import Success from "./Proposal/Checkout/Success";
import Details from "./Proposal/Checkout/Details";
import Booking from "./Booking";


import { Group, Loader, LoadingOverlay } from '@mantine/core';
import { Navigate, useLocation, useParams } from "react-router-dom";
import Header from "./Proposal/Header";

const CheckoutRoot = styled.div`
    position: relative;
`
const CheckoutContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    height: fit-content;
    flex-direction: column;

    @media (min-width: 768px) {
        width: 720px;
    }

    @media (min-width: 992px) {
        width: 960px;
    }

    @media (min-width: 1300px) {
        width: 1200px;

        display: flex;
        flex-direction: row;
    }
`

const CheckoutColumn = styled.div`
    width: 80%;
    margin: 10px auto;
    padding: 20px;

    @media (min-width: 1300px) {
        width: 30%;
        padding: 0;
        margin: 20px;
    }
`

const CheckoutTitle = styled.h1`
    margin: 20px 20px 20px 0;
    color: #17C0B9;
    font-size: 24px;
    text-align: center;

    @media (min-width: 1300px) {

        text-align: left;
    }
`


const appearance : Appearance = {
    theme: 'stripe',

    variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Montserrat, sans-serif',
        fontSizeBase: '19px',
        spacingUnit: '8px',
        borderRadius: '4px',
        // See all possible variables below
      }
  };


//const stripe_uk = loadStripe("pk_live_51HaCGwDyy41spbtsV6Sj25hiYZiSdJdlXMDZSz7QZAN6U8Os9SwmqgpnNOAprdSMOXBU8VVywAUUARXS9fOZvh5z00GcL90l0N")
//const stripe_au = loadStripe("pk_live_ziYfjK1noEi0sJHDjz0UxMFB");

//console.log(process.env.REACT_APP_AU_STRIPE)

const stripe_au = loadStripe(process.env.REACT_APP_AU_STRIPE!,{
    betas : ["blocked_card_brands_beta_2"]
  });
const stripe_uk = loadStripe(process.env.REACT_APP_UK_STRIPE!,{
    betas : ["blocked_card_brands_beta_2"]
  });



function Checkout(){
    const [clientSecret, setClientSecret] = useState("");
    const [back, setBack] = useState(false);
    const [complete, setComplete] = useState(false);
    const { gstTotal, proposal, paymentLoading, setProposal, paymentSuccess, setLoading, getProposal, loading, found} = useProposal();
    let { id } = useParams();
    const stripe = proposal.country === "AU" ? stripe_au : stripe_uk;

    const location = useLocation();
    const  from = location.state?.from


    useEffect(() => {

        async function wait(){

            if(from !== null) {
                //console.log(from)
                if(gstTotal != null && gstTotal > 0) {
                    setupPayment(gstTotal, proposal.country, proposal.id)
                    .then((data) => {
                        //console.log(data)
                        setClientSecret(data.clientSecret)
                        setProposal({...proposal, customer_id: data.customer_id})
                        });
                }
    
                if(!found) setBack(true)
       
                return;
            }
            

            getProposal(id)
            .then(() => {
                //console.log(proposal)
                if(!proposal.paidAt && proposal.status !== "PAID") setLoading(false);

                if(gstTotal != null && gstTotal > 0) {
                    setupPayment(gstTotal, proposal.country, proposal.id)
                    .then((data) => {
                        //console.log(data)
                        setClientSecret(data.clientSecret)
                        setProposal({...proposal, customer_id: data.customer_id})
                     });
                }

    
            })


            // Create PaymentIntent as soon as the page loads

        }
        wait()
        .catch(err => console.error(err))


      }, [id, getProposal, gstTotal]);
    

    const options = {
        clientSecret,
        appearance,
        disallowedCardBrands  : ["discover_global_network"]
    };
    
    return (
        
            <CheckoutRoot>
                {back && <Navigate to={"/"+id} />}
                <Header loading={false}/>
                {paymentSuccess ? 
                <Success />
                : 
                <CheckoutContainer>
                    <CheckoutColumn>
                        <CheckoutTitle>Your Cart</CheckoutTitle>
                        <Cart />
                    </CheckoutColumn>
                    <CheckoutColumn>
                        <CheckoutTitle>Your Details</CheckoutTitle>
                        <Details />
                    </CheckoutColumn>
                    <CheckoutColumn>
                        <CheckoutTitle>Payment</CheckoutTitle>
                        {clientSecret && (
                        <Elements options={options} stripe={stripe}>
                            <Payment />
                        </Elements>
                        )}
                        {!clientSecret && (
                            <Group position="center">
                                <Loader color="#17C0B9" size="xl" />
                            </Group>
                        )}
                    </CheckoutColumn>
                </CheckoutContainer>
                }
                <LoadingOverlay visible={paymentLoading || loading} overlayBlur={2} loader={<Loader color="#17C0B9" size="lg" />} />
            </CheckoutRoot>
    )
}

export default Checkout;