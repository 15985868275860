export const UK_LAWYERS = [
    {
        name: "Alex Solo",
        img: "https://sprintlaw.com.au/wp-content/uploads/2020/12/Alex-1024x1024.jpg",
        title: "Co-founder & Principal",
        description: "Alex is an experienced technology, media and commercial lawyer, specialising in software licences, SaaS and app agreements and complex service agreements. Alex has an intimate understanding of the tech industry, having previously founded and sold a digital agency and worked as a tech lawyer at a top-tier law firm.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Tomoyuki Hachigo",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Tomo-300x300.jpg",
        title: "Co-founder & Principal",
        description: "Tomoyuki is an experienced corporate lawyer. He has experience advising blue chip companies such as Virgin, Mitsubishi, CIMIC, Skilled Group and Macquarie Bank in M&A transactions, as well as advising many startups and small businesses on subscription & shareholders agreements, MOUs, term sheets, equity agreements and corporate law issues.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Claudia Hoeben",
        img: "https://sprintlaw.com.au/wp-content/uploads/2021/07/Claudia-Headshot-1024x1024.jpg",
        title: "Legal Team Leader",
        description: "Claudia is Legal Team Leader at Sprintlaw. Claudia has top-tier law firm and in-house experience from Australia, Tokyo and New York, where she has worked on commercial, intellectual property and technology contracts and transactions.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Adil Khan",
        img: "https://sprintlaw.com.au/wp-content/uploads/2021/07/Adil-1024x1024.jpg",
        title: "Managing Director",
        description: "Adil is a legal expert with experience supporting the delivery of complex advisory solutions to private and public sector actors around the world. Adil’s broad professional experience includes advising corporate clients on transactional matters, operational management in fast-growth start-ups and working with a range of stakeholders on progressive institutional reform.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Ishita Luthra",
        img: "https://sprintlaw.co.uk/wp-content/uploads/2021/09/Ishita.png",
        title: "Senior Lawyer",
        description: "Ishita is a corporate lawyer at Sprintlaw UK. She is dual-qualified to practice in the UK and India. Ishita has previously worked at prominent law firms in London and Mumbai and is experienced in all areas of corporate and commercial law, with a specialisation in private M&A, capital raising and corporate structuring.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Claudia Zimmerman",
        img: "https://sprintlaw.com.au/wp-content/uploads/2022/06/Claudia-Zimmerman-1024x1024.jpg",
        title: "Lawyer",
        description: "Claudia is a commercial lawyer at Sprintlaw. Claudia has a strong background in a wide range of commercial areas, including commercial contracts, data privacy, technology law, corporate law, intellectual property and more. She particularly enjoys helping startups and small businesses navigate legal issues and processes.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Vladimir Kravchenko",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Vlad-300x300.jpg",
        title: "Legal Team Leader",
        description: "Vladimir is a commercial lawyer, with specialisation in commercial contracts and regulatory advice. He previously worked as an in-house counsel for one of Australia’s big banks, advising on regulatory enquiries, AFSL and ACL issues and drafting and reviewing general commercial contracts.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Amelia Abbott",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Amelia-300x300.jpg",
        title: "Legal Team Leader",
        description: "Amelia is a commercial and corporate lawyer, with specialisation in technology-related contracts, privacy and corporate law. Previously working for two national law firms, she has acted for major public sector clients, including the Australian Federal Police, Australia Post and the Department of Defence, as well as advising many tech startups and SMEs.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Embeth Sadie",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Embeth-300x300.jpg",
        title: "Senior Lawyer",
        description: "Embeth is a lawyer at Sprintlaw. Having previously practised at a commercial litigation firm, Embeth has a deep understanding of commercial law and how to identify the legal needs of businesses.",
        linkedin: 'linkedin.com'
    },
]