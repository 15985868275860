import React, { useState } from 'react';
import { IProduct } from "../../types/Proposal";

import useMediaQuery from '@mui/material/useMediaQuery';

import { 
    TableRow,
    TableBody,
    IconButton,
    Collapse,
    Checkbox
 } from '@mui/material'
 
import { useProposal } from '../../providers/ProposalProvider';

import { 
    ProposalRow ,
    ProposalHead,
    ProposalCell,
    BoldProposalCell,
    Description,
    Expanded,
    Collapsed,
    ProposalTable,
    ProposalTableContainer,
    HeaderCell,
    ExpandCell,
    CollapsableRow,
    MobileCheckbox,
    ExpandHeaderCell,
    HiddenCell
} from './ProposalTable';

import parse from 'html-react-parser';

interface RowProps {
    product: IProduct
    complete?: boolean
    currency: string
}

interface IRowType {
    product: IProduct,
    open: boolean,
    setOpen: Function,
    complete?: boolean
    currency: string
}

function MobileRow(props : IRowType ){
    const { product, open, setOpen, complete, currency } = props;
    
    return (
        <ProposalRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <ExpandCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <Expanded /> : <Collapsed />}
                </IconButton>
            </ExpandCell>
            <BoldProposalCell component="th" scope="row">
                {product.name}
                <br />
                {currency}{product.price.toFixed(2)}
                <br />
            </BoldProposalCell>
            {!complete && 
            <ProposalCell>
                <MobileCheckbox      
                    checked={product.selected}
                    aria-label={product.name + " checkbox"}
                    disabled
                />
            </ProposalCell>
            }
        </ProposalRow>
    )
}

function DesktopRow(props : IRowType){

    const { product, open, setOpen, complete, currency } = props;

    const { discounted } = useProposal();
    
    return (
        <ProposalRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <ExpandCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <Expanded /> : <Collapsed />}
                </IconButton>
            </ExpandCell>
            <BoldProposalCell component="th" scope="row">
                {product.name}
            </BoldProposalCell>
            <ProposalCell colSpan={2}>
            {currency}{product.price.toFixed(2)}
            </ProposalCell>
            {discounted &&
            <HiddenCell colSpan={2}>
            </HiddenCell>
            }
            {!complete &&
            <ProposalCell>
                <Checkbox      
                    checked={product.selected}
                    aria-label={product.name + " checkbox"}
                    disabled
                />
            </ProposalCell>
            }
        </ProposalRow>
    )
}

function Row(props : RowProps){

    const mobile = useMediaQuery('(max-width:768px)');

    const { complete, product, currency } = props;

    const [open, setOpen] = useState(complete ? true : false);


    //const discount = product.discount_type == "flat" ? "$" + product.discount!.toFixed(2) : product.discount!.toFixed(2) + "%";
    return(
        <React.Fragment>
            {mobile ? 
                <MobileRow product={product} open={open} setOpen={setOpen} complete={complete} currency={currency} />
                :
                <DesktopRow product={product} open={open} setOpen={setOpen} complete={complete} currency={currency} />
            }

            <CollapsableRow>
                <ProposalCell style={{ padding: 0, width: "100%" }} colSpan={7}>
                    <Collapse in={open} timeout="auto" aria-label={product.name + " scope"}>
                        <Description sx={{ margin: 1 }}>
                            {parse(product.description)}
                        </Description>
                    </Collapse>
                </ProposalCell>
            </CollapsableRow>
        </React.Fragment>
    )
}

interface IGovTable {
    complete?: boolean
}


function GovTable( props : IGovTable ) {
    
    const { complete } = props;

    const { discounted, govProducts, currency, taxType } = useProposal();

    return(
        <ProposalTableContainer >
            <ProposalTable aria-label="collapsible table">
                <ProposalHead>
                    <TableRow>
                        <ExpandHeaderCell colSpan={1}  align="left"></ExpandHeaderCell>
                        <HeaderCell colSpan={1}  align="left">Description</HeaderCell>
                        <BoldProposalCell align="left" colSpan={2}>Price (ex {taxType})</BoldProposalCell>
                        {discounted && <BoldProposalCell align="left" colSpan={2}></BoldProposalCell>}
                        {!complete && <BoldProposalCell align="left" colSpan={2}>Select</BoldProposalCell>}
                    </TableRow>
                </ProposalHead>
                <TableBody>
                    {govProducts.map((row, index) => 
                    (
                        <Row key={row.name+"-"+index} product={row} complete={complete} currency={currency} />
                    ))}
                </TableBody>
            </ProposalTable>
        </ProposalTableContainer>
    )
}

export default GovTable;
