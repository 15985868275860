import { Container, Group, Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useProposal } from '../../../providers/ProposalProvider';
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import styled from 'styled-components';

import { 
    Button as MUIButton, ThemeProvider, createTheme,
  } from '@mui/material'

import { Lawyer, a11yProps, TabDiv, TabPanelProps } from '../OurLawyers';
import { LawyerData } from '../../Booking';
import { Box, Tab, Tabs } from '@mui/material';
import { checkAvailability, completeBooking } from '../../../services/APIService';


const theme = createTheme({
  palette: {
    primary: {
      main: "#17C0B9",
    },
    secondary: {
      main: "#17C0B9",
    },
  },
});


const Title = styled.h1`
    font-family: Montserrat;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    color: #17C0B9;
    margin-top: 60px;
`

const Description = styled.p`
    margin: auto;
    font-family: Montserrat;
    margin-top: 40px;
    margin-bottom: 30px;
    color: black;
    font-size: 20px ;
    padding-left: 10px;
    line-height: 1.5;
`

const LawyerBox = styled(Box)`
    padding: 0px !important;
    height: 100%;

`

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <TabDiv
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <LawyerBox sx={{ p: 3 }}>
            {children}
          </LawyerBox>
        )}
      </TabDiv>
    );
  }

const Root = styled(Container)`
  max-width: 1100px;
}
`

const CalendlyPanel = styled(TabPanel)`
  width: 100% !important;
  height: 1000px !important;
  @media (max-width: 768px) {
    height: 1100px !important;
  }
`

const TabsContainer = styled(Tabs)`
  width: 1000px;

  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    height: fit-content;
}
`

export const Calendly = styled(InlineWidget)`
  height: 990px !important;
  width: 100% !important;

  padding: 0px;

  @media (max-width: 768px) {
    width: 400px !important;
  }
`

const LawyerTab = styled(Tab)`
  padding-bottom: 0px !important;
  height: 320px;
  width: 160px;

  @media (max-width: 768px) {
    width: 150px;
    height: fit-content;
  }

  '&.Mui-selected': {
    color: #17C0B9;
  }
  '&.Mui-focusVisible': {
    backgroundColor: #17C0B9;
  }
`

const LawyerImage = styled.img`
    height: 110px;
    width: 110px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;

    @media (max-width: 768px) {
        height: 90px;
        width: 90px;
    }
`
const LawyerNameContainer = styled.div`
    height: 100px;

    @media (max-width: 768px) {
      height: 150px;
    }
`
const LawyerLabel = styled.h2`
  line-height: 1.5;
  height: 60px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`

const AvailableTime = styled.h3`
    font-size: 11px;
    line-height: 1.5;
    color: black;
`
const Time = styled.span`
    color: black;
    font-weight: 500;
`

const LawyerBio = styled(Lawyer)`
  min-height: 0 !important;
`

const style = {
  height: '100%',
  width: '100%',
  marginTop: '-50px',
  '@media (max-width: 768px)': {
      width: '400px',
  }
}

const BackButton = styled(MUIButton)`
    display: block !important;
    width: 200px !important;
    margin: 10px auto !important;
    margin-bottom: 40px !important;
    padding: 10px !important;
    background-color: white !important;
    color: grey !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border: 1px solid grey !important;
`

interface LawyerCalendlyProps {
    lawyer: LawyerData
    complete: (lawyer : LawyerData, eventURL : string, inviteeURL: string) => void

}


export default function LawyerCalendly(props : LawyerCalendlyProps) {

    //const navigate = useNavigate();
    const { details_form } = useProposal();

    const { lawyer , complete} = props

    useCalendlyEventListener({
        onProfilePageViewed: () => {
          //console.log("onProfilePageViewed " + lawyer['Staff Name'])
        },
        onDateAndTimeSelected: () => {
          //console.log("onDateAndTimeSelected")
        },
        onEventTypeViewed: () => {
          //console.log("onEventTypeViewed " + lawyer['Staff Name'])
        },
        onEventScheduled: (e) => {
          //console.log(e.data.payload)
          //console.log("booked " + lawyer['Staff Name'])
          complete(lawyer, e.data.payload.event.uri, e.data.payload.invitee.uri)
        },
      });

    return  (
    <InlineWidget 
        url={lawyer['Calendly URL']}
        prefill={{
            email: details_form.values.email,
            firstName: details_form.values.first_name,
            lastName: details_form.values.last_name,
        }}
        styles={style}
    />
    )
}