import styled from 'styled-components'
import { useProposal } from '../../providers/ProposalProvider';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const PaidAlert = styled(Alert)`

    width: 55%;
    margin: 0 auto !important;

    padding: 10px !important;
    height: 120px;

    margin-top: 20px !important;
    margin-bottom: 20px !important;
    border-radius: 10px !important;
`

const PaidAlertTitle = styled(AlertTitle)`
    font-weight: 600 !important;
    font-size: 1rem !important;
    font-family: Montserrat !important;
`


const PaidText = styled.p`
    margin: 0 !important;
    max-width: 100%;
    margin-top: 10px !important;
    font-size: 0.9rem;
    font-family: Montserrat;
`

function Booked(){
    const { proposal } = useProposal();

    
    let booking_date = proposal.booking_date ? 
    new Date(proposal.booking_date)
    .toLocaleDateString('en', {weekday: 'long', year: 'numeric', day: 'numeric', month: 'long',  hour: '2-digit', minute: '2-digit', timeZoneName: 'long'})
     : "";
    return (
        <PaidAlert severity="success" >
            <PaidAlertTitle>Call booked</PaidAlertTitle>
            <PaidText>Your call has been booked with <b>{proposal.lawyer}</b> at <b>{booking_date}</b>. A calendar invite with the booking details has been sent to your email address. </PaidText>

        </PaidAlert>
    )
}

export default Booked;