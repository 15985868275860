import styled from "styled-components";
import {Collapse, TextField} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input'
import { useMemo, useState } from "react";
import { Switch, Loader} from '@mantine/core';

import { useProposal } from "../../../providers/ProposalProvider";
import HelpTooltip from "../HelpTooltip";
import InputAdornment from '@mui/material/InputAdornment';

import { lookupABN, lookupACN } from "../../../services/APIService";

import { usePlacesWidget } from "react-google-autocomplete";

import { TiTick } from 'react-icons/ti'
import { IoMdClose } from 'react-icons/io'

const DetailsContainer = styled.div`
`

const Input = styled(TextField)`
    width: 100%;



    input {
        font-family: 'Montserrat' !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
`

const PhoneInput = styled(MuiTelInput)`
    width: 100%;
    font-family: 'Montserrat' !important;
    input {
        font-family: 'Montserrat' !important;
    }
`

const InputText = styled.p`

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 19.3651px;
    line-height: 29px;
    width: 100%;
    padding: 10px 0;
`

const ErrorText = styled.p`
    color: #d32f2f;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 5px;
    margin-left: 10px;
`

const AddressErrorText = styled.p`
    color: #d32f2f;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 5px;
    margin-left: 10px;
`

interface InputContainerProps {
    error?: boolean
}
const InputContainer = styled.div<InputContainerProps>`
    width: 100%;
    height: ${props => props.error ? "140px" : "120px"};
`
const AddressInputContainer = styled.div<InputContainerProps>`
    width: 100%;
    height: ${props => props.error ? "160px" : "140px"};
`

const AddressContainer = styled.div<InputContainerProps>`
    width: 100%;
    height: ${props => props.error ? "100px" : "80px"};
`

const Required = styled.span`
    color: red;
`



const DetailsSwitch = styled(Switch)`
    font-family: 'Montserrat' !important;
    padding: 5px;
    label {
        font-family: 'Montserrat' !important;
    }
`

function Details(){

    const { proposal, details_form, individual, setIndividual, noCompanyNumber, setNoCompanyNumber, manualAddress, setManualAddress } = useProposal();

    const [foundTax, setFoundTax ] = useState(false);
    const [errorTax, setErrorTax ] = useState(false);

    const [prefilledAddress, setPrefilledAddress] = useState(proposal.formatted_address ? true : false);
    //onsole.log(proposal.company_number)

    const addressEntered = useMemo(() => {
        if(details_form.values.address_search !== "") {
            return true;
        }

        return false;
    }, [details_form])

    const makeJSON = ( raw : any) => JSON.parse(raw.replace('callback(', '').replace('})', '}'));

    const validCompanyNumber = useMemo(() => {
        let valid = false;
        setErrorTax(false);
        setFoundTax(false);

        if(proposal.country == "AU"){
            //console.log(String(details_form.values.company_number).length)
            if(String(details_form.values.company_number).length == 9){
                valid = true;
                lookupACN(String(details_form.values.company_number).trim()).then((response) => {
                    let res = makeJSON(response)
                    //console.log(res)
                    if(res.Message !== ""){
                        setFoundTax(true);
                        return;
                    } else {
                        setFoundTax(true);
                    }
                    details_form.setFieldValue("company_name", res.EntityName);
                    if(res.Abn) details_form.setFieldValue("additional_company_number", res.Abn);
                });
            } else if(String(details_form.values.company_number).length == 11){
                valid = true;
                lookupABN(String(details_form.values.company_number).trim()).then((response) => {
                    let res = makeJSON(response)
                    //console.log(res)
                    if(res.Message !== ""){
                        setErrorTax(true);
                        return;
                    } else {
                        setFoundTax(true);
                    }
                    details_form.setFieldValue("company_name", res.EntityName);
                    if(res.Acn) details_form.setFieldValue("additional_company_number", res.Acn);
                });
            }
        }

        return valid;

    }, [details_form.values.company_number])

    const CompanyNumberAdornment = useMemo(() => {

        
        if(validCompanyNumber && foundTax){
            return (
            <InputAdornment position="end">
                <TiTick color="#17C0B9" />
            </InputAdornment>
            )
        }

        if(validCompanyNumber && errorTax){
            return (
            <InputAdornment position="end" >
                <IoMdClose color="#d32f2f"  />
            </InputAdornment>
            )
        }

        if(validCompanyNumber && !foundTax && !errorTax){
            return (
            <InputAdornment position="end">
                <Loader size="xs" color="#17C0B9"/>
            </InputAdornment>
            )

        }
    }, [validCompanyNumber, foundTax, errorTax])

    const TooltipText = "Please get in touch with our team if you require assistance";

    const Currency = proposal.country === "UK" ? "£" : "$";

    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyDXF6BOrxZhbdzG5gLT0w_nAJRPXDNtSmM",
        onPlaceSelected: (place) => {

          try {

            if(proposal.formatted_address) setPrefilledAddress(false)
            //console.log(place);
            details_form.setFieldValue("address_search", place.formatted_address);
            details_form.setFieldValue("place_id", place.place_id);
            details_form.setFieldValue("address_line_1", place.address_components[0].short_name + " " + place.address_components[1].short_name);
            details_form.setFieldValue("city", place.address_components[2].short_name);
            details_form.setFieldValue("state", place.address_components[4].short_name);
            details_form.setFieldValue("postcode", place.address_components[6].short_name);
            details_form.setFieldValue("country", place.address_components[5].long_name);
            details_form.setFieldValue("formatted_address", place.formatted_address);
          }
          catch(e){
            console.error(e)
          }


        },
        options: {
            types: ["address"],
        }
      });
    
    const handleCompanyNumber = (e : any) => {
        details_form.setFieldValue("company_number", e.target.value.replace(/\s/g, ''));
    };

    return (
        <DetailsContainer>

                <InputContainer error={details_form.touched.first_name && Boolean(details_form.errors.first_name)} >
                    <InputText>First Name <Required>*</Required></InputText>
                    <Input 
                        placeholder="First name" 
                        name="first_name"
                        onChange={details_form.handleChange} 
                        value={details_form.values.first_name} 
                        error={details_form.touched.name && Boolean(details_form.errors.first_name)}
                        helperText={details_form.touched.name && (details_form.errors.first_name)} 
                    />
                </InputContainer>
                <InputContainer error={details_form.touched.last_name && Boolean(details_form.errors.last_name)} >
                    <InputText>Last Name <Required>*</Required></InputText>
                    <Input 
                        placeholder="Last name" 
                        name="last_name"
                        onChange={details_form.handleChange} 
                        value={details_form.values.last_name} 
                        error={details_form.touched.last_name && Boolean(details_form.errors.last_name)}
                        helperText={details_form.touched.last_name && (details_form.errors.last_name)} 
                    />
                </InputContainer>
                <InputContainer error={details_form.touched.email && Boolean(details_form.errors.email)}>
                    <InputText>Email <Required>*</Required></InputText>
                    <Input 
                        placeholder="Email" 
                        name="email"
                        onChange={details_form.handleChange} 
                        value={details_form.values.email} 
                        error={details_form.touched.email && Boolean(details_form.errors.email)}
                        helperText={details_form.touched.email && (details_form.errors.email)} 
                    />
                </InputContainer>
                <InputContainer error={details_form.touched.phone_number && Boolean(details_form.errors.phone_number)}>
                    <InputText>Phone Number <Required>*</Required></InputText>
                    <PhoneInput 
                        name="phone_number"
                        required
                        preferredCountries={['AU', 'GB']} 
                        forceCallingCode  
                        defaultCountry={proposal.country === "UK" ? "GB" : "AU"}
                        onChange={(e) => details_form.setFieldValue('phone_number', e)} 
                        value={details_form.values.phone_number} 
                        error={details_form.touched.phone_number && Boolean(details_form.errors.phone_number)}
                        helperText={details_form.touched.phone_number && (details_form.errors.phone_number)} 
                    />
                </InputContainer>

                { (!noCompanyNumber) &&
                <>
                <InputContainer error={details_form.touched.company_number && Boolean(details_form.errors.company_number) || individual}>
                    <InputText>{proposal.country === "AU" ? "ABN or ACN" : "Company Number"} {!noCompanyNumber && <Required>*</Required>}</InputText>
                    <Input 
                        placeholder={proposal.country === "AU" ? "ABN or ACN" : "Company Number"}
                        name="company_number"
                        onChange={handleCompanyNumber} 
                        value={details_form.values.company_number}
                        error={details_form.touched.company_number && Boolean(details_form.errors.company_number)}
                        disabled={proposal.company_number != undefined && proposal.client_number ? true : false}
                        helperText={details_form.touched.company_number && (details_form.errors.company_number)} 
                        type="text"
                        InputProps={{
                            endAdornment: CompanyNumberAdornment,
                          }}
                    />

                </InputContainer>
                {errorTax && <ErrorText>Invalid ABN or ACN</ErrorText>}
                </>

                }
                {<DetailsSwitch onChange={(event) => setNoCompanyNumber(event.currentTarget.checked)} checked={noCompanyNumber} label={proposal.country === "AU" ? "I don't have an ABN or ACN" : "I have no Company Number"}/>}
                {!individual && 
                <>
                <InputContainer error={details_form.touched.company_name && Boolean(details_form.errors.company_name)}>
                    
                    <InputText>Business / Company Name {(proposal.company_name && proposal.client_number) && <HelpTooltip label={TooltipText} aria="Help Tooltip" /> } <Required>*</Required></InputText>
                    <Input 
                        placeholder="Business name" 
                        name="company_name"
                        onChange={details_form.handleChange} 
                        value={details_form.values.company_name} 
                        error={details_form.touched.company_name && Boolean(details_form.errors.company_name)}
                        disabled={(proposal.company_name && proposal.client_number ? true : false) || ( foundTax && String(details_form.values.company_number).length !== 9)}  
                        helperText={details_form.touched.company_name && (details_form.errors.company_name)} 
                    />
                </InputContainer>
                </>
                }
                {<DetailsSwitch onChange={(event) => setIndividual(event.currentTarget.checked)} checked={individual} label="I am an individual"/>}
                


                
                <AddressInputContainer error={details_form.touched.address_search && Boolean(details_form.errors.address_search)}>
                    <InputText>Address {!manualAddress && <Required>*</Required>}</InputText>
                    {prefilledAddress && <AddressErrorText>Please confirm your address using the dropdown.</AddressErrorText>}
                    <Input 
                        placeholder="Address"
                        name="address_search"
                        inputRef={ref}
                        value={details_form.values.address_search} 
                        onChange={details_form.handleChange} 
                        disabled={manualAddress}
                        error={details_form.touched.address_search && Boolean(details_form.errors.address_search)}
                        helperText={details_form.touched.address_search && (details_form.errors.address_search)} 
                    />
                    
                </AddressInputContainer>
                <DetailsSwitch onChange={(event) => setManualAddress(event.currentTarget.checked)} checked={manualAddress} label="Manually enter address"/>

                <Collapse in={manualAddress || addressEntered} style={{marginTop: "30px"}}>

                    <>
                        <AddressContainer error={details_form.touched.address_line_1 && Boolean(details_form.errors.address_line_1)}>
                            <Input 
                                name="address_line_1"
                                placeholder="Address Line 1 *"
                                onChange={details_form.handleChange} 
                                value={details_form.values.address_line_1} 
                                disabled={!manualAddress}
                                error={details_form.touched.address_line_1 && Boolean(details_form.errors.address_line_1)}
                                helperText={details_form.touched.address_line_1 && (details_form.errors.address_line_1)} 
                            />
                        </AddressContainer>
                        <AddressContainer >
                            <Input 
                                name="address_line_2"
                                placeholder="Address Line 2"
                                onChange={details_form.handleChange} 
                                value={details_form.values.address_line_2} 
                                disabled={!manualAddress}
                                
                            />
                        </AddressContainer>
                        <AddressContainer error={details_form.touched.city && Boolean(details_form.errors.city)} >
                            <Input 
                                name="city"
                                placeholder={proposal.country === "AU" ? "City *" : "Town / City *"}
                                onChange={details_form.handleChange} 
                                value={details_form.values.city} 
                                error={details_form.touched.city && Boolean(details_form.errors.city)}
                                helperText={details_form.touched.city && (details_form.errors.city)} 
                                disabled={!manualAddress}
                            />
                        </AddressContainer>
                        <AddressContainer error={details_form.touched.state && Boolean(details_form.errors.state)} >
                            <Input 
                                name="state"
                                placeholder={proposal.country === "AU" ? "State *" : "County / State *"}
                                onChange={details_form.handleChange} 
                                value={details_form.values.state} 
                                error={details_form.touched.state && Boolean(details_form.errors.state)}
                                helperText={details_form.touched.state && (details_form.errors.state)} 
                                disabled={!manualAddress}
                            />
                        </AddressContainer>
                        <AddressContainer error={details_form.touched.postcode && Boolean(details_form.errors.postcode)}>
                            <Input 
                                name="postcode"
                                placeholder="Post Code *"
                                onChange={details_form.handleChange} 
                                value={details_form.values.postcode} 
                                error={details_form.touched.postcode && Boolean(details_form.errors.postcode)}
                                helperText={details_form.touched.postcode && (details_form.errors.postcode)} 
                                disabled={!manualAddress}
                            />
                        </AddressContainer>
                        <AddressContainer error={details_form.touched.country && Boolean(details_form.errors.country)}>
                            <Input 
                                name="country"
                                placeholder="Country *"
                                onChange={details_form.handleChange} 
                                value={details_form.values.country} 
                                error={details_form.touched.country && Boolean(details_form.errors.country)}
                                helperText={details_form.touched.country && (details_form.errors.country)} 
                                disabled={!manualAddress}
                            />
                        </AddressContainer>
                    </>
                    
                </Collapse>

            


        </DetailsContainer>
    )
}

export default Details;