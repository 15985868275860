import { Container, Group, Loader, LoadingOverlay, createStyles, Text} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useProposal } from '../../../providers/ProposalProvider';
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import styled from 'styled-components';

import { 
    Button as MUIButton, ThemeProvider, createTheme,
  } from '@mui/material'

import { Lawyer, a11yProps, TabDiv, TabPanelProps } from '../OurLawyers';
import { LawyerData } from '../../Booking';
import { Box, Tab, Tabs } from '@mui/material';
import { checkAvailability, completeBooking } from '../../../services/APIService';
import LawyerCalendly from './LawyerCalendly';
import { getAuth } from 'firebase/auth';

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: 80,
    paddingBottom: 120,
    [theme.fn.smallerThan('sm')]: {
        paddingTop: 200,
    },
    //center vertically
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },

  title: {
    fontFamily: `Montserrat`,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 40,
    color: "#17C0B9",

    [theme.fn.smallerThan('sm')]: {
      fontSize: 35,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
    color: "#17C0B9",
    fontSize: 24,
    fontWeight: 400,
    fontFamily: `Montserrat`,
  },
}));


const theme = createTheme({
  palette: {
    primary: {
      main: "#17C0B9",
    },
    secondary: {
      main: "#17C0B9",
    },
  },
});


const Title = styled.h1`
    font-family: Montserrat;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    color: #17C0B9;
    margin-top: 60px;
`

const Description = styled.p`
    margin: auto;
    font-family: Montserrat;
    margin-top: 40px;
    margin-bottom: 30px;
    color: black;
    font-size: 20px ;
    padding-left: 10px;
    line-height: 1.5;
    text-align: center;
`

const SubText = styled.p`
    margin: auto;
    font-family: Montserrat;
    margin-top: 20px;
    margin-bottom: 30px;
    color: black;
    font-size: 16px ;
    padding-left: 10px;
    line-height: 1.5;
    text-align: center;
    max-width: 600px;
`

const Link = styled.a`
  color: #17C0B9;
`

const LawyerBox = styled(Box)`
    padding: 0px !important;
    height: 100%;

`



const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 400px;
`

const LoaderText = styled.p`
  position: relative;
  color: #17C0B9;
  text-align: center;
`

const LoaderWheel = styled(Loader)`
  padding: 20px;
  margin: 0 auto;
`

function OverlayLoader(){
  return (
      <LoaderContainer>
          <LoaderWheel color="#17C0B9" size="lg" />
          <LoaderText>Please wait this may take a moment...</LoaderText>
      </LoaderContainer>
  )
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <TabDiv
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <LawyerBox sx={{ p: 3 }}>
            {children}
          </LawyerBox>
        )}
      </TabDiv>
    );
  }

interface IRoot {
  $lawyers?: boolean
}
const Root = styled(Container)<IRoot>`
  max-width: 1100px;
  min-height: ${(props) => props.$lawyers ? '100vh' : '10vh'};

`

const BookedRoot = styled(Container)`
  max-width: 1100px;
  margin: 30px;

`



const CalendlyPanel = styled(TabPanel)`
  width: 100% !important;
  height: 1000px !important;
  @media (max-width: 768px) {
    height: 1100px !important;
  }
`

const TabsContainer = styled(Tabs)`
  width: 1000px;

  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    height: fit-content;
}
`

export const Calendly = styled(InlineWidget)`
  height: 990px !important;
  width: 100% !important;

  padding: 0px;

  @media (max-width: 768px) {
    width: 400px !important;
  }
`

const LawyerTab = styled(Tab)`
  padding-bottom: 0px !important;
  height: 320px;
  width: 160px;

  @media (max-width: 768px) {
    width: 150px;
    height: fit-content;
  }

  '&.Mui-selected': {
    color: #17C0B9;
  }
  '&.Mui-focusVisible': {
    backgroundColor: #17C0B9;
  }
`

const LawyerImage = styled.img`
    height: 110px;
    width: 110px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;

    @media (max-width: 768px) {
        height: 90px;
        width: 90px;
    }
`
const LawyerNameContainer = styled.div`
    height: 100px;

    @media (max-width: 768px) {
      height: 150px;
    }
`
const LawyerLabel = styled.h2`
  line-height: 1.5;
  height: 60px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`

const AvailableTime = styled.h3`
    font-size: 11px;
    line-height: 1.5;
    color: black;
`
const Time = styled.span`
    color: black;
    font-weight: 500;
`

const LawyerBio = styled(Lawyer)`
  min-height: 0 !important;
`


const BackButton = styled(MUIButton)`
    display: block !important;
    width: 200px !important;
    margin: 10px auto !important;
    margin-bottom: 40px !important;
    padding: 10px !important;
    background-color: white !important;
    color: grey !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    border: 1px solid grey !important;
    font-family: Montserrat !important;
`

interface LawyerBookingProps {
    lawyers: LawyerData[]
    setFetch: React.Dispatch<React.SetStateAction<boolean>>,
    fetch: boolean
}

function NoLawyersClient(){
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
        <Title className={classes.title}>No Times Available</Title>
        <Text size="lg" align="center" className={classes.description}>
          Apologies, there are no longer any times available for self-serve booking. <br />
          Our team will assign a lawyer for you within the next business day and contact you via email to schedule a time. <br />
          You’ll now be redirected.
        </Text>
        <Group position="center">
            <Loader color="#17C0B9" size="xl" />
        </Group>
    </Container>
  )
}


export default function LawyerBooking(props : LawyerBookingProps) {

  const auth = getAuth();
  const user = auth.currentUser;
    //const navigate = useNavigate();
    const { details_form, setLoading, proposal} = useProposal();

    const { lawyers, setFetch, fetch } = props
    
    const [availableLawyers, setAvailableLawyers] = useState<LawyerData[]>([]);
    const navigate = useNavigate();
    
    const [booked, setBooked] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {

        async function availability(){
            let temp : LawyerData[] = [...lawyers]
            if(availableLawyers.length > 0){
              return;
            }
            
            for(let i = 0; i < temp.length; i++){
              try {
                var res = await checkAvailability(temp[i]["Calendly Event ID"])

                if(res.status == null){
                  temp[i]["Next Available Time"] = "No availability within 7 days"
                }
                else {
                  temp[i]["Next Available Time"] = new Date(res.start_time).toLocaleDateString("en-AU", {
                      weekday: "short",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      timeZone: "Australia/Sydney"
                    }) + " " + new Date(res.start_time).toLocaleTimeString("en-AU", {timeZone: "Australia/Sydney",  hour: "2-digit", minute: "2-digit" }) 
                }
                
                if(i === temp.length - 1) setFetch(false)

              }
              catch(err){
                console.error(err);
                //sendToManual();
              }
                



      
                //console.log(a)


                //temp.push(lawyers[i])

            }   
            
 
            let availLawyers = temp.filter(lawyer => lawyer['Next Available Time'] !== "No availability within 7 days");
            //console.log( availLawyers.length)
            
            if(availLawyers.length === 0){
              //console.log("No Lawyers Avail")
              sendToManual();
            }


            setAvailableLawyers(availLawyers)
            //setFetch(false)
        }

        if(lawyers) availability()

    }, [lawyers])


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    async function sendToManual(){
        //console.log("MANUAL")
        setFetch(true)
        setLoading(true)
        setTimeout(() => {

          completeBooking({ id: proposal.id, booked: false, country: proposal.country, manual: true })
          .then(() => {
            navigate("/" + proposal.id + "/complete");
          })
          setLoading(false)
        }, 4000);
        
    }

    async function bookLater(){
        setLoading(true)
        await completeBooking({ id: proposal.id, booked: false, country: proposal.country })
        setLoading(false)
        navigate("/" + proposal.id + "/complete");
    }

    async function complete(lawyer : LawyerData, eventURL : string, inviteeURL : string){
      setBooked(true)
      await completeBooking({ id: proposal.id, booked: true, country: proposal.country, lawyer: lawyer['Staff Name'], inviteeURL: inviteeURL, eventURL: eventURL})

      const timer = setTimeout(() => {
          setLoading(true)
          navigate("/" + proposal.id + "/complete");
      }, 5000);
      return () => clearTimeout(timer);
    }

    const EMAIL = proposal.country === "AU" ? "team@sprintlaw.com.au" : "team@sprintlaw.co.uk"

    return   (
      
      <Root $lawyers={availableLawyers !== null}>
        <ThemeProvider theme={theme}>
            <Title>Please book in a time to chat with your lawyer.</Title>
            {availableLawyers.length > 1 &&  <Description>The below lawyers all have expertise in the service areas relevant to your legal project.</Description>}
            <SubText>Please note that occasionally we may reallocate your project to another lawyer, including due to changes in availablity. If you have any issues making a booking please contact us at <Link href={"mailto:"+EMAIL}>{EMAIL}</Link></SubText>
            <TabsContainer value={value} onChange={handleChange} aria-label="basic tabs example"  centered={true} >
            {availableLawyers && availableLawyers.map((lawyer, index) => {
                return (
                    <LawyerTab key={lawyer['Staff Name']+"-tab-"+index} label={
                      <LawyerNameContainer>
                        <LawyerLabel>{lawyer['Staff Name']}</LawyerLabel><AvailableTime>Next Available: <br /> <Time>{lawyer['Next Available Time']}</Time></AvailableTime>
                      </LawyerNameContainer>
                    } icon={<LawyerImage alt={lawyer['Staff Name']} src={lawyer['Photo URL']} />} {...a11yProps(index)} />

                )
            })}
            </TabsContainer>

            {availableLawyers && availableLawyers.map((lawyer, index) => {
                return (
                    <CalendlyPanel value={value} index={index} key={lawyer['Staff Name']+"-panel-"+index}>

                        <LawyerBio 
                            name={lawyer['Staff Name']}
                            title={lawyer['Title'][0]}
                            description={lawyer['Bio']}
                            booking={true}
                        />
                        {booked &&       
                        <BookedRoot>
                            <Title>Booking complete.</Title>
                            <Description>
                                Thank you - you will now be redirected.
                            </Description>
                            <Group position="center">
                                <Loader color="#17C0B9" size="xl" />
                            </Group>
                        </BookedRoot> }
                        {!booked &&
                        <LawyerCalendly
                              lawyer={lawyer} 
                              complete={complete}
                        />
                        }
                    </CalendlyPanel>
                )
            })}

            {!booked &&
            <BackButton variant='outlined' onClick={() => bookLater()}>Book Later</BackButton>
            }

            </ThemeProvider>
            <LoadingOverlay visible={fetch} overlayBlur={2} loader={<OverlayLoader/>}  styles={{height: "100vh !important"}}>Please wait this may take a few moments...</LoadingOverlay>
      </Root>
    )
}