import React from 'react';
import '././css/global.css';
import Proposal from './components/Proposal';
import Complete from './components/Complete';
import { ProposalProvider } from './providers/ProposalProvider';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import NotFound from './components/NotFound';
import Checkout from './components/Checkout';
import Brief from './components/Brief';
import Booking from './components/Booking';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDm8SmnAIBh7bBjxWtjR6CzTAxTUXpBWnA",
    authDomain: "sprintlaw-proposal.firebaseapp.com",
    projectId: "sprintlaw-proposal",
    storageBucket: "sprintlaw-proposal.appspot.com",
    messagingSenderId: "996817438698",
    appId: "1:996817438698:web:17a5967330221fc206d493",
    measurementId: "G-8H3SYWWRC9"
};

function App() {

  initializeApp(firebaseConfig);


  return (
      <BrowserRouter>
      <ProposalProvider>
          <Routes>
            <Route path="/" element={<NotFound />} />

            <Route path=":id/complete" element={<Complete />} />

            <Route path=":id" element={<Proposal />} />

            <Route path=":id/checkout" element={<Checkout />} />
            
            <Route path=":id/brief" element={<Brief />} />

            <Route path=":id/booking" element={<Booking />} />
            
          </Routes>
        </ProposalProvider>
      </BrowserRouter>
  );
}

export default App;
