import styled from 'styled-components'
import { useProposal } from '../../providers/ProposalProvider';
import { Button } from '@mui/material';
import { Link as RouteLink } from "react-router-dom";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';



interface IAlertProps {
    $warning?: boolean;
}
const PaidAlert = styled(Alert)<IAlertProps>`

    width: 55%;
    margin: 0 auto !important;

    padding: 10px !important;
    height: ${(props) => !props.$warning ? 140 : 100}px !important;

    margin-top: 20px !important;
    border-radius: 10px !important;
    margin-bottom: 20px !important;
`

const PaidAlertTitle = styled(AlertTitle)`
    font-weight: 600 !important;
    font-size: 1rem !important;
    font-family: Montserrat !important;
`


const PaidText = styled.p`
    margin: 0 !important;
    max-width: 100%;
    margin-top: 10px !important;
    font-size: 0.9rem;
    font-family: Montserrat;
`


const Link = styled(RouteLink)`
    color: #17C0B9;
    font-weight: 600;
    min-width: 100px;
    min-height: 50px;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 20px;
`

const BookButton = styled(Button)`
    height: 30%;
    width: 30%;
    margin: 0 auto !important;
    background: #a75c5b !important;
    color: white !important;
    font-size: 0.8rem !important;
    padding: 5px !important;
    font-family: Montserrat !important;
`



const ReturnButton = styled(Button)`
`

function NotBooked(){
    const { paidAt , proposal } = useProposal();

    const Engagement = proposal.country === "AU" ? "https://www.sprintlaw.com.au/engagement-letter" : "https://www.sprintlaw.co.uk/engagement-letter";
    const Terms = proposal.country === "AU" ? "https://www.sprintlaw.com.au/terms-and-conditions" : "https://www.sprintlaw.co.uk/terms-and-conditions";
    const CompanyNumber  = (): string => {
        if(proposal.company_number === null || proposal.company_number === "" ){
            return "";
        }

        if(proposal.country === "AU" && proposal.company_number){
            if(proposal.company_number!.toString().trim().length === 9){
                return "ACN";
            } else {
                return "ABN";
            }
        }

        if(proposal.country === "UK" && proposal.company_number){
            return "Company number";
        }

        return "";
    }
    
    return proposal.booking_status === "NO LAWYERS AVAILABLE" ? (
        <PaidAlert severity="warning"  $warning={true}>
            <PaidAlertTitle>Booking status</PaidAlertTitle>
            <PaidText>Auto-booking is not currently available for this project. Our team will be in touch shortly to organise a time to speak over email.</PaidText>
        </PaidAlert>
    ) : (
        <PaidAlert severity="error" >
            <PaidAlertTitle>Action required</PaidAlertTitle>
            <PaidText>You haven't yet booked in a call with one of our lawyers for this project.</PaidText>
            <Link to={`/${proposal.id}/booking`}>
                <BookButton color="inherit" size="small">
                Book Now
                </BookButton>
            </Link>
        </PaidAlert>
    )
}

export default NotBooked;