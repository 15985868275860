import React, { ChangeEvent, useState } from 'react';
import { IProduct } from "../../types/Proposal";

import useMediaQuery from '@mui/material/useMediaQuery';

import { 
    TableRow,
    TableBody,
    IconButton,
    Collapse,
    Checkbox
 } from '@mui/material'
 
import { useProposal } from '../../providers/ProposalProvider';

import { 
    ProposalRow ,
    ProposalHead,
    ProposalCell,
    BoldProposalCell,
    Description,
    Expanded,
    Collapsed,
    ProposalTable,
    ProposalTableContainer,
    HeaderCell,
    ExpandCell,
    CollapsableRow,
    MobileCheckbox,
    MobileDiscount,
    MobilePrice,
    NameCell,
    ExpandHeaderCell
} from './ProposalTable';

import parse from 'html-react-parser';

interface RowProps {
    product: IProduct
    setProductSelected: Function
    discounted: boolean
    complete?: boolean
    currency: string
}

interface IRowType {
    product: IProduct,
    discounted: boolean,
    open: boolean,
    setOpen: Function,
    selected: boolean,
    handleSelected: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void   
    complete?: boolean
    currency: string
}

function MobileRow(props : IRowType ){
    const { product, selected, handleSelected, discounted, open, setOpen, complete, currency } = props;
    
    const disabled = (product.optional) ? false : true;

    return (
        <ProposalRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <ExpandCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <Expanded /> : <Collapsed />}
                </IconButton>
            </ExpandCell>
            <BoldProposalCell component="th" scope="row">
                {product.name}
                <br />
                <MobilePrice discounted={product.discount ? true : false}>{currency}{product.price.toFixed(2)}</MobilePrice>
                {product.discount! > 0 && <MobileDiscount>{currency}{discounted && discountedPrice(product)}</MobileDiscount>}
            </BoldProposalCell>
            {!complete && 
            <ProposalCell>
                <MobileCheckbox      
                    checked={selected}
                    onChange={handleSelected} 
                    disabled={disabled}
                    aria-label={product.name + " checkbox"}
                />
            </ProposalCell>
            }
        </ProposalRow>
    )
}

function DesktopRow(props : IRowType){

    const { product, selected, handleSelected, discounted, open, setOpen, complete, currency} = props;

    const disabled = (product.optional ) ? false : true;
    
    return (
        <ProposalRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <ExpandCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <Expanded /> : <Collapsed />}
                </IconButton>
            </ExpandCell>
            <NameCell component="th" scope="row">
                {product.name}
            </NameCell>
            <ProposalCell colSpan={2}>
                {currency}{product.price.toFixed(2)}
            </ProposalCell>
            {discounted && 
            <ProposalCell colSpan={2}>
                {currency}{discount(product)}
            </ProposalCell>
            }
            {!complete &&
            <ProposalCell>
                <Checkbox      
                    checked={selected}
                    onChange={handleSelected} 
                    disabled={disabled}
                    aria-label={product.name + " checkbox"}
                />
            </ProposalCell>
            }
        </ProposalRow>
    )
}

const discountedPrice = (product: IProduct) : string => {
    if(product.discount){
        return (product.price - product.discount!).toFixed(2).toString();
    }
    return "0.00"
};

const discount = (product: IProduct) : string => {
    if(product.discount){
        return (product.discount!.toFixed(2)).toString();
    }
    return "0.00"
}

function Row(props : RowProps){

    const { product, setProductSelected, discounted, complete, currency } = props;

    const mobile = useMediaQuery('(max-width:768px)');

    const [open, setOpen] = useState(complete ? true : false);
    const [selected, setSelected] = useState(product.selected!);


    const handleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.checked);
        setProductSelected(product.name, event.target.checked);
    };

    //const discount = product.discount_type == "flat" ? "$" + product.discount!.toFixed(2) : product.discount!.toFixed(2) + "%";
    return(
        <React.Fragment>
            {mobile ? 
            <MobileRow 
                product={product} 
                discounted={discounted} 
                open={open} 
                setOpen={setOpen} 
                selected={selected} 
                handleSelected={handleSelected} 
                complete={complete} 
                currency={currency}
            />
            :
            <DesktopRow 
                product={product} 
                discounted={discounted} 
                open={open} 
                setOpen={setOpen} 
                selected={selected} 
                handleSelected={handleSelected} 
                complete={complete} 
                currency={currency}
            />
            }

            <CollapsableRow>
                <ProposalCell style={{ padding: 0, width: "100%" }} colSpan={7}>
                    <Collapse in={open} timeout="auto" aria-label={product.name + " scope"}>
                        <Description sx={{ margin: 1 }}>
                            {parse(product.description)}
                        </Description>
                    </Collapse>
                </ProposalCell>
            </CollapsableRow>
        </React.Fragment>
    )
}
interface IAddonsTable {
    complete?: boolean
}

function AddonsTable( props: IAddonsTable){

    const { complete } = props;

    const discount =  (element : IProduct) => element.discount;

    const { setProductSelected, optionalProducts, proposal, currency, taxType } = useProposal();

    const hasDiscount = optionalProducts.some(discount);

    return(
        <ProposalTableContainer >
            <ProposalTable aria-label="collapsible table">
                <ProposalHead>
                    <TableRow>
                        <ExpandHeaderCell colSpan={1}  align="left"></ExpandHeaderCell>
                        <HeaderCell colSpan={1}  align="left">Description</HeaderCell>
                        <BoldProposalCell align="left" colSpan={2}>Price (ex {taxType})</BoldProposalCell>
                        {hasDiscount && <BoldProposalCell align="left" colSpan={2}>Discount</BoldProposalCell>}
                        {!complete && <BoldProposalCell align="left" colSpan={2}>Select</BoldProposalCell>}
                    </TableRow>
                </ProposalHead>
                <TableBody>
                    {optionalProducts.map((row,index) => (
                        <Row key={row.name+"-"+index} product={row} discounted={hasDiscount} setProductSelected={setProductSelected} complete={complete} currency={currency}/>
                    ))}
                </TableBody>
            </ProposalTable>
        </ProposalTableContainer>
    )
}

export default AddonsTable;