import styled from 'styled-components'
import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { auto } from '@popperjs/core';
import { useProposal } from '../../providers/ProposalProvider';
import { AU_LAWYERS } from '../../data/AU_LAWYERS';
import { UK_LAWYERS } from '../../data/UK_LAWYERS';

const Container = styled.div`
    @media (max-width: 768px) {
        min-height: 500px !important;
    }
`

const LawyerContainer = styled.div<any>`
    width: 80%;
    position: relative;
    min-height: ${props => props.booking ? "0" : "250px"};;
    margin: 0 auto;
    padding: 20px;
`

export const LawyerImage = styled.img`
    height: 90px;
    width: 90px;
    border-radius: 50%;

    position: absolute;
    top: 30px;
    left: 20px;

    @media (max-width: 768px) {
        height: 90px;
        width: 90px;
    }
`

const LawyerName = styled.h2`
    color: #23C0B9;
    min-width: fit-content;
    position: relative;
    margin: 0;

    display: inline-block;

    width: 100%;

    padding: 5px;

    @media (max-width: 768px) {

        text-align: center;

        width: 100%;

        font-size: 16px;
    }
`
const LawyerTitle = styled.h2`
    color: black;
    width: 200px;
    position: relative;


    width: 100%;

    display: inline-block;

    padding: 5px;
    
    @media (max-width: 768px) {
        position: relative;
        text-align: center;
        margin: 10px auto;

        width: 100%;
    }
`


const LawyerDescription = styled.p`
    color: black;
    max-width: 100%;
    position: relative;
    margin: 20px 0;
    padding: 5px;

    line-height: 1.5;

    @media (max-width: 768px) {
        margin-top: 20px;
        position: relative;
        text-align: center;
        max-width: 100%;

        top: 0px;
        left: 0px;
    }
`


const LawyerBox = styled(Box)`
    height: 200px !important;

`

export const TabDiv = styled.div`


    @media (max-width: 768px) {
        min-height: 470px;
    }
`

export interface LawyerProps {
    name: string,
    title: string,
    description: string,
    booking: boolean
}

export const Lawyer = (props : LawyerProps) => {
    return (
        <LawyerContainer booking={props.booking}>
            <LawyerName>{props.name}</LawyerName>            
            <LawyerTitle>{props.title}</LawyerTitle>
            <LawyerDescription>{props.description}</LawyerDescription>
        </LawyerContainer>
    )
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <TabDiv
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <LawyerBox sx={{ p: 3 }}>
            {children}
          </LawyerBox>
        )}
      </TabDiv>
    );
  }
  
export function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

export const LawyerTab = styled(Tab)`
  height: 180px;
  width: 140px;

  @media (max-width: 768px) {
    width: 120px;
    height: fit-content;
}
`
export const LawyerLabel = styled.h2`
  padding-top: 110px;
`

export const TabsContainer = styled(Tabs)`
  width: 800px;

  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%;
}
`

const SubText = styled.p`
    font-size: 16px;
    color: grey;

    padding: 10px 0;
    width: 100%;
    text-align: center;

    font-weight: 600;
`


function OurLawyers(){
   
    const [value, setValue] = useState(0);

    const mobile = useMediaQuery('(max-width:768px)');
    const {proposal } = useProposal();
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const Lawyers = proposal.country === "AU" ? AU_LAWYERS : UK_LAWYERS

    //MAP THIS BASED ON JSON DATA
    return (
        
        <Container>
            <SubText>You'll be working with one of our experienced lawyers.</SubText>
            <TabsContainer value={value} onChange={handleChange} aria-label="basic tabs example" variant={"scrollable"}  scrollButtons={auto}>
            {Lawyers.map((lawyer, index) => {
                return (
                    <LawyerTab key={lawyer.name+"-tab-"+index} label={<LawyerLabel>{lawyer.name}</LawyerLabel>} icon={<LawyerImage alt={lawyer.name} src={lawyer.img} />} {...a11yProps(index)} />
                )
            })}
            </TabsContainer>
            {Lawyers.map((lawyer, index) => {
                return (
                    <TabPanel value={value} index={index} key={lawyer.name+"-panel-"+index}>
                        <Lawyer 
                            name={lawyer.name}
                            title={lawyer.title}
                            description={lawyer.description}
                            booking={false}
                        />
                    </TabPanel>
                )
            })}


        </Container>


    )
}

export default OurLawyers;