import React from 'react';
import styled from 'styled-components'
import { Group, Stack, Center } from '@mantine/core';


import { AiOutlineMail } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import { CgFileDocument } from 'react-icons/cg'

const Container = styled.div`
    width: 100%;
    min-height: 250px;

`

const MailIcon = styled(AiOutlineMail)`
    height: 60px;
    width: 60px;
`

const PhoneIcon = styled(BsTelephone)`
    height: 60px;
    width: 60px;
`

const DocIcon = styled(CgFileDocument)`
    height: 60px;
    width: 60px;
`

const Header = styled.h1`
`

const Desc = styled.p`
    font-size: 15px;
`

const GCon = styled(Stack)`
    padding: 20px;
    display: flex;
    width: 300px;
    height: 250px;
    text-align: center;
`


function HowItWorks(){

    const Accept = "Accept and pay for your fixed-fee quote. No credit card fees :)"
    const Lawyer = "Your lawyer will provide an online link to our call booking system. Book in a time to chat."
    const Payment = "Receive your completed document or deliverable, usually within 5 - 7 business days."
    
    return(
        <Container>
            <Group position="center" spacing="xl">
                <GCon>
                    <Center><DocIcon /></Center>
                    <Header>1 / Accept This Quote </Header>
                    <Desc>{Accept}</Desc>
                </GCon>
                <GCon>
                    <Center><PhoneIcon /></Center>
                    <Header>2 / Speak with a lawyer </Header>
                    <Desc>{Lawyer}</Desc>
                </GCon>
                <GCon>
                    <Center><MailIcon /></Center>
                    <Header>3 / Payment </Header>
                    <Desc>{Payment}</Desc>
                </GCon>
            </Group>
        </Container>
    )
}

export default HowItWorks;