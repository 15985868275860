import { createStyles, Image, Container, Title, Text, Button, SimpleGrid, ButtonProps } from '@mantine/core';
import TopSecret from '../../img/TopSecret.svg';
import { GoogleIcon } from './GoogleIcon';
import { MouseEventHandler } from 'react';
import styled from 'styled-components';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  signInButton: {
    marginTop: theme.spacing.md,
  },
}));


const SignInButton = styled(GoogleButton)`
  margin: 30px 0;
`


interface IGoogleButton {
  children: string;
  onClick: React.MouseEventHandler<HTMLElement>
  props?: ButtonProps
}
export function GoogleButton (props: IGoogleButton) {
  return <Button leftIcon={<GoogleIcon />} variant="default" color="gray" {...props} />;
}


export default function BriefSignIn({signIn} : {signIn: Function}) {
  const { classes } = useStyles();

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={80} cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}>
        <Image src={TopSecret} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>Access Denied</Title>
          <Text color="dimmed" size="lg">
            The page you are trying to access is restricted. Please sign in to continue.
          </Text>
          <SignInButton onClick={() => signIn()} >Sign in with Google</SignInButton>

        </div>
        <Image src={TopSecret} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  );
}