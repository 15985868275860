import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { 
  Checkbox,
} from '@mui/material'

import styled from "styled-components";
import { useProposal } from "../../../providers/ProposalProvider";
import { Center, Loader } from '@mantine/core';
import { Link as RouteLink } from "react-router-dom";

interface IPayButton {
  disabled: boolean;
}

const PayButton = styled.button<IPayButton>`
  height: 50px;
  width: 100%;
  margin: 20px auto;
  color: white;
  background: ${(props) => props.disabled ? "#a19e9d" : "#17C0B9" };
  padding: 12px 51px;
  box-shadow: 0px -1.07126px 1.07126px rgba(0, 0, 0, 0.12), 0px 2.14252px 5.35629px rgba(0, 0, 0, 0.12), 0px 1.07126px 1.07126px rgba(0, 0, 0, 0.08);
  border-radius: 4.3px;

  cursor: pointer;

  transition: background 0.4s;
`

const Form = styled.form`
  width: 100%;
  font-family: 'Montserrat', sans-serif !important;
`

const Error = styled.div`
  width: 100%;
  color: red;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`
const PriceCheckbox = styled(Checkbox)`
    padding: 20px !important;
`

const CheckboxContainer = styled(Center)`


`

const Link = styled.a`
    color: #17C0B9;
    font-weight: 600;
`

const Title = styled.p`
    font-size: 14.25px;
    color: #6d6e78;
    line-height: 20.6px;
    font-family: Arial;
    text-align: center;
`


export default function Payment() {
  const stripe = useStripe();
  const elements = useElements();
  const { gstTotal, proposal, details_form, paymentLoading, setPaymentLoading, payment_completed, setProposal } = useProposal();

  const [submitted, setSubmitted] = useState(false);
  
  const [message, setMessage] = useState("");

  const Currency = proposal.country === "UK" ? "£" : "$";

  const Engagement = proposal.country === "AU" ? "https://www.sprintlaw.com.au/engagement-letter" : "https://www.sprintlaw.co.uk/engagement-letter";
  const Terms = proposal.country === "AU" ? "https://www.sprintlaw.com.au/terms-and-conditions" : "https://www.sprintlaw.co.uk/terms-and-conditions";

  useEffect(() => {
    //console.log(elements)
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case "succeeded":
          //console.log(paymentIntent)
    
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e : React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitted(true);
    await details_form.handleSubmit();




    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setSubmitted(false);
      return;
    }

    if(!details_form.isValid){
      //console.log("Form is not valid")
      //console.log(details_form.values)
      setSubmitted(false);
      return; 
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://proposal.sprintlaw.co/"+proposal.id+"/complete",
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if(error){
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message!);
      } else {
        setMessage("An unexpected error occurred.");
      }
    }

    if(paymentIntent){
      //console.log(paymentIntent)
      setPaymentLoading(true);
      payment_completed(paymentIntent); 
      setSubmitted(false);
    }



  };



  return (
      <Form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />



        <PayButton disabled={paymentLoading || !stripe || !elements || submitted} id="submit" type="submit">

          <span id="button-text">
            {paymentLoading ?  <Loader color="white" size="sm" variant="dots" /> : "Pay now "+Currency + gstTotal.toFixed(2)}
          </span>
        </PayButton>
        {/* Show any error or success messages */}
        {message && <Error id="payment-message">{message}</Error>}

        <CheckboxContainer>
          <Title>By proceeding, you agree to our <br /> <Link href={Terms} target="_blank" >Terms & Conditions</Link> and <Link href={Engagement} target="_blank" >Engagement Letter</Link></Title>
        </CheckboxContainer>

      </Form>

  );
}