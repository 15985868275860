import styled from 'styled-components'
import { useProposal } from '../../providers/ProposalProvider';
import { Group, Stack, Divider, Center} from '@mantine/core';
import { 
    Checkbox,
    Button,
} from '@mui/material'

import { BsClockHistory } from 'react-icons/bs'
import HelpTooltip from './HelpTooltip';
import { Link as RouteLink } from "react-router-dom";
import { useMemo } from 'react';


const PricePhantom = styled.div`
    height: 250px;
    margin-top: 200px;

    @media (max-width: 768px) {
        display: none;
    }
`

interface IPrice {
    complete?: boolean;
}
const PriceContainer = styled.div<IPrice>`
    width: 100%;
    min-height: fit-content;
    position: ${props => props.complete ? "relative": "fixed" };
    bottom: 0;
    z-index: 1;
    background-color: #F3F3F3;

    @media (max-width: 768px) {
        position: relative;
    }
`

const PriceBanner = styled.div`
    width: 100%;
    height: 10px;
    background-color: #17C0B9;
`
const PriceSummary = styled.div`
    padding: 20px;
    width: 30%;
    height: 100%;

    @media (max-width: 768px) {
        width: 100%;

    }
`

const ValueContainer = styled.div`
`

const PriceText = styled.h2`
    float: left;
    font-weight: 600;
`
const PriceValue = styled.h2`
    float: right;
    font-weight: 600;
`


const CheckoutButton = styled(Button)`
    background: #17C0B9 ${props => props.disabled ? "": "!important" };
    margin: 0 auto !important;
    color: white !important;
    height: 50px;
    width: 250px;
    font-weight: 600 !important;
    font-family: Montserrat !important;
    border-radius: 20px !important;
`

const Desc = styled.p`

`

const GCon = styled(Stack)`
    padding: 20px;
    display: flex;
    width: 300px;
    text-align: center;
    margin: 0 auto;


    @media (max-width: 768px) {
        padding: 5px;
    }
`

const DeliveryIcon = styled(BsClockHistory)`
    height: 60px;
    width: 60px;
`

const PriceGroup = styled(Group)`

    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const CheckboxContainer = styled(Center)`


`

const Link = styled.a`
    color: #17C0B9;
    font-weight: 600;
`

const Title = styled.h1`
    width: 270px;
`

interface ICheckoutLink {
    $disabled: boolean;
}
const CheckoutLink = styled(RouteLink)<ICheckoutLink>`
    pointer-events: ${props => props.$disabled ? "none": "auto" };
    text-decoration: none;
    margin: 0 auto !important;
`


interface IPrice {
    complete?: boolean
}

const PriceCheckbox = styled(Checkbox)`
    padding: 20px !important;
`




function Price(props : IPrice ){

    const { totalNoDiscount, proposal, setProposal, gstTotal, gst, setCheckout, discounted, discountPrice, memberPrice, currency, taxType, express} = useProposal();

    const { complete } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProposal({ ...proposal, signed: event.target.checked})
    };

    const disabled = useMemo(() => {
        return (proposal.signed && totalNoDiscount > 1) ? false : true;
    }, [ totalNoDiscount, proposal.signed]);
    

    const MembershipTooltip = proposal.country === "AU" ? "Promo price for first year only. Renews annually at $799 + GST. Cancel any time." : "Promo price for first year only. Renews annually at £399 + VAT. Cancel any time.";

    const Engagement = proposal.country === "AU" ? "https://www.sprintlaw.com.au/engagement-letter" : "https://www.sprintlaw.co.uk/engagement-letter";
    const Terms = proposal.country === "AU" ? "https://www.sprintlaw.com.au/terms-and-conditions" : "https://www.sprintlaw.co.uk/terms-and-conditions";
    return ( 
        <>
            {!complete && <PricePhantom/>}
            <PriceContainer complete={complete} >
                <PriceBanner />
                <PriceGroup position="center">
                    <PriceSummary>
                        <Stack>
                            <ValueContainer>
                                <PriceText>Subtotal</PriceText> <PriceValue>{currency}{totalNoDiscount.toFixed(2)}</PriceValue>
                            </ValueContainer>
                            { proposal.member_upsell && 
                            <ValueContainer>
                                <PriceText>Membership Offer<HelpTooltip label={MembershipTooltip} aria="Optional Addons Tooltip" /></PriceText> <PriceValue>{currency}{memberPrice.toFixed(2)}</PriceValue>
                            </ValueContainer>
                            }
                            { discounted &&
                            <ValueContainer>
                                <PriceText>Fixed Fee Discount</PriceText> <PriceValue>-{currency}{discountPrice.toFixed(2)}</PriceValue>
                            </ValueContainer>
                            }
                            <ValueContainer>
                                <PriceText>{taxType}</PriceText> <PriceValue>{currency}{gst.toFixed(2)}</PriceValue>
                            </ValueContainer>
                            <Divider />
                            <ValueContainer>
                                <PriceText>Grand Total</PriceText> <PriceValue>{currency}{gstTotal.toFixed(2)}</PriceValue>
                            </ValueContainer>
                        </Stack>
                    </PriceSummary>
                    { !complete &&
                    <PriceSummary>
                        <GCon>
                            <Center><DeliveryIcon /></Center>
                            <Desc> Your estimated delivery time will be in {!express ? proposal.delivery_date : proposal.express_delivery_date} business days</Desc>
                        </GCon>
                    </PriceSummary>}
                    { !complete &&
                    <PriceSummary>
                        <Center>
                            <Stack>
  

                                <CheckboxContainer>
                                    <PriceCheckbox
                                        onChange={handleChange}
                                        value={proposal.signed}
                                        aria-label="Agree to Terms and Conditions and Engagement Letter Checkbox"
                                        disabled={proposal.status !== "ACTIVE"}
                                    />
                                    <Title>Please confirm you accept our <Link href={Terms} target="_blank" >T&Cs</Link> and <Link href={Engagement} target="_blank" >Engagement Letter</Link></Title>
                                </CheckboxContainer>
                                <CheckoutLink to={`/${proposal.id}/checkout`} $disabled={disabled} state={{ from: "PROPOSAL"}} >
                                    <CheckoutButton disableElevation variant="contained"  disabled={disabled}>
                                    GO TO CHECKOUT
                                    </CheckoutButton>
                                </CheckoutLink>


                            </Stack>
                        </Center>
                    </PriceSummary>}
                    
                </PriceGroup>
            </PriceContainer>
        </>
    )
}



export default Price;