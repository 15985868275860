import styled from 'styled-components'

import parse from 'html-react-parser';
import { useProposal } from '../../providers/ProposalProvider';

const NotesContainer = styled.div`
    margin: 10px auto 40px;
`

const NotesText = styled.p`
    line-height: 1.5;
`

function AdditionalNotes() {

    const { proposal } = useProposal();
    
    return(
        <NotesContainer> 
            <NotesText>
                {parse(proposal.additional_notes!)}
             </NotesText>
        </NotesContainer>
    )
}

export default AdditionalNotes;