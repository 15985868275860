import { Button, Modal, useMediaQuery } from '@mui/material';
import styled from 'styled-components'


import DiscountIcon from '../../img/DiscountIcon.svg';
import DocumentIcon from '../../img/DocumentIcon.svg';
import CallIcon from '../../img/CallIcon.svg';
import SignaturesIcon from '../../img/SignaturesIcon.svg';
import { Center, Stack} from '@mantine/core';
import { useProposal } from '../../providers/ProposalProvider';


const ModalContainer = styled.div`
    height: 400px;
    width: 370px;

    background-color: #F9F9F9;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 20px;

    @media (min-width: 768px) {
        height: 1150px;
        width: 700px;
    }

    @media (min-width: 992px) {
        height: 1200px;
        width: 850px;
    }

    @media (min-width: 1300px) {
        height: 630px;
        width: 900px;
    }
`

const Logo = styled.img`
    height: 70px;
    width: 70px;
    padding:20px;

    position: absolute;
    left: -50px;
`

const IconContainer = styled.div`

    width: 400px;
    height: 850px;

    display:grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row: auto;
    grid-column-gap: 50px;
    grid-row-gap: 5px;

    margin: 0 auto;

    @media (min-width: 1300px) {
        grid-template-columns: repeat(2, 1fr);

        width: 800px;
        height: 320px;

        margin: 20px auto 0 80px;
    }
`

const TextContainer = styled.div`
    width: 340px;
    height: 130px;

    background-color: #fff;

    display:flex;
    align-items:center;
    justify-content:center;

    position: relative;

    border-radius: 40px;

`

const BoxTitle = styled.h1`

    color: #17C0B9;
    text-align: center;
`

const BoxText = styled.p`
    color: #454545;

    text-align: center;
    padding: 10px 20px;
    margin: 0 20px;
    font-size: 15px;
`

const Title = styled.h1`
    margin: 0 auto;
    font-size: 32px;
    width: fit-content;

    padding: 20px;

    color: #17C0B9;

    font-weight: 600;

    text-align: center;

    @media (max-width: 768px) {
        font-size: 34px;
    }

`

const Subtitle = styled.p`
    margin: 0 auto;
    width: 300px;
    text-align: center;
    font-weight: 600;

    font-size: 18px;

    padding: 5px;
    line-height: 1.5;

    @media (min-width: 768px) {
        width: 600px;
    }

    @media (min-width: 992px) {
        width: 800px;
    }


`

const LegalNeeds = styled.p`
    font-size: 16px;
    font-weight: 600;
    
    padding: 0 0 30px 0;
    margin: 0 auto;

    @media (min-width: 1300px) {
        width: 650px;
    }
    
`

const Price = styled.span`
    color: #17C0B9;
    font-weight: 600;
`

const ButtonCenter = styled(Center)`

`

const CloseButton = styled(Button)`
    background: #17C0B9 ${props => props.disabled ? "": "!important" };
    margin: 0 auto !important;
    color: white !important;
    height: 50px;
    width: 250px;
    font-weight: 600 !important;
    font-family: Montserrat !important;
    border-radius: 20px !important;
`

const MobileLink = styled.a`
    color: #17C0B9;
    font-weight: 600;
`

const MobileText = styled.p`
    font-size: 17px;


    padding: 10px 20px 40px 20px;
    line-height: 1.5;
    text-align: center;
`

interface ModalProps {
    open : boolean,
    handleClose : () => void
}

function LearnMoreModal(props : ModalProps){
    const { open, handleClose } = props;

    const { memberDiscount, memberPrice, proposal } = useProposal();

    const mobile = useMediaQuery('(max-width:768px)');

    const MemberLink = proposal.country === "AU" ? "https://www.sprintlaw.com.au/membership" : "https://www.sprintlaw.co.uku/membership";
    const SignaturePrice = proposal.country === "UK" ? "£1" : "$1.45";
    const Currency = proposal.country === "UK" ? "£" : "$";
    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <ModalContainer>
                <Title>
                    Sprintlaw Membership
                </Title>
                {!mobile &&
                <Subtitle>
                    <LegalNeeds>By adding the Sprintlaw Membership, you'll save <Price>{Currency}{memberDiscount}</Price> on the project, and the first year fee will only cost <Price>{Currency}{memberPrice}</Price> {memberDiscount > memberPrice && "(making it cheaper overall!)"}</LegalNeeds>
                </Subtitle>
        }
                <Subtitle>
                    What's included?
                </Subtitle>
                {mobile && 
                <>
                    <MobileText>Members recieve <b>discounts</b> on our services, access to our <b>legal helpline</b>, <b>free documents</b> and <b>e-signatures</b>, and <MobileLink href={MemberLink} target="_blank" >more</MobileLink>. </MobileText>
                </>
                }
                {!mobile &&
                <IconContainer>
                    <TextContainer>
                        <Logo src={CallIcon} />
                        <Stack spacing="xs">
                            <BoxTitle>
                                Lawyer Calls
                            </BoxTitle>
                            <BoxText>
                                Book a free call with a lawyer whenever you need help, advice or answers.
                            </BoxText>
                        </Stack>
                    </TextContainer>
                    <TextContainer>
                        <Logo src={DiscountIcon} />
                        <Stack spacing="xs">
                            <BoxTitle>
                            Discounted Legal Work
                            </BoxTitle>
                            <BoxText>
                            Receive a 10% membership discount on all our fixed-fee legal packages.
                            </BoxText>
                        </Stack>
                    </TextContainer>
                    <TextContainer>
                        <Logo src={SignaturesIcon} />
                        <Stack spacing="xs">
                            <BoxTitle>
                            Sprintlaw Signatures
                            </BoxTitle>
                            <BoxText>
                            Access our eSignatures tool to get documents electronically signed and witnessed ({SignaturePrice} per doc)
                            </BoxText>
                        </Stack>
                    </TextContainer>
                    <TextContainer>
                        <Logo src={DocumentIcon} />
                        <Stack spacing="xs">
                            <BoxTitle>
                                Document Updates
                            </BoxTitle>
                            <BoxText>
                                Our lawyers can make free quick updates to any documents we’ve prepared for you.
                            </BoxText>
                        </Stack>
                    </TextContainer>
                </IconContainer>
                }
                <ButtonCenter>
                    <CloseButton onClick={handleClose}> GOT IT </CloseButton>
                </ButtonCenter>

            </ModalContainer>
        </Modal>        
    )
}

export default LearnMoreModal;