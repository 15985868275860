import styled from 'styled-components'
import { 
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableHead,
    Box,
    Checkbox
 } from '@mui/material'

 import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export const ProposalTableContainer = styled(TableContainer)`
   box-shadow: 0;
`

export const ProposalTable = styled(Table)`
    box-shadow: 0;
 `

export const ProposalRow = styled(TableRow)`
    background-color: #F3F3F3;

    @media (max-width: 768px) {
      height: 100px !important;
   }
`
export const CollapsableRow = styled(TableRow)`
    background-color: #F3F3F3;
`

export const ProposalHead = styled(TableHead)`
    background-color: #ffffff;

    @media (max-width: 768px) {
      display: none !important;
   }
 `

export const BoldProposalCell = styled(TableCell)`
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif !important;
    border-bottom: 2px solid white !important;
`

export const NameCell = styled(TableCell)`
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif !important;
    border-bottom: 2px solid white !important;

    min-width: 450px !important;
    max-width: 470px !important;

    @media (max-width: 768px) {
      min-width: unset;
      max-width: unset;
   }
`

export const ExpandCell = styled(TableCell)`
   font-size: 16px !important;
   font-family: 'Montserrat', sans-serif !important;
   border-bottom: 2px solid white !important;

   width: 40px !important;

   @media (max-width: 768px) {
      padding: unset;
   }
`

export const HeaderCell = styled(TableCell)`
   font-size: 16px !important;
   font-weight: 600 !important;
   font-family: 'Montserrat', sans-serif !important;
   border-bottom: 2px solid white !important;
   min-width: 300px !important;
`

export const ExpandHeaderCell = styled(TableCell)`
   font-size: 16px !important;
   font-weight: 600 !important;
   font-family: 'Montserrat', sans-serif !important;
   border-bottom: 2px solid white !important;
   max-width: 40px !important;
`

export const ProposalCell = styled(TableCell)`
    font-size: 16px !important;
    font-family: 'Montserrat', sans-serif !important;
    border-bottom: 2px solid white !important;
 `

 export const HiddenCell = styled(TableCell)`
    font-size: 16px !important;
    font-family: 'Montserrat', sans-serif !important;
    border-bottom: 2px solid white !important;
    min-width: 30px !important;
 `

export const Description = styled(Box)`
    background-color: white;
    padding: 30px;
    margin: 2px !important;

    > ul {
         list-style-type: disc;
         padding-left: 30px;
    }

    > ul > li {
         padding: 5px;
    }
 `
export const MobileQty = styled.p`
   text-align: left;
   padding-left: 15px;
`

export const MobileCheckbox = styled(Checkbox)`
   margin: 0 auto !important;
`

export const MobileDiscount = styled.p`
   color: #5ac461;
`

interface IMobilePrice {
   discounted: boolean
}
export const MobilePrice = styled.p<IMobilePrice>`
   text-decoration: ${props => props.discounted ? 'line-through' : 'none'};
`


export const Expanded = styled(MdKeyboardArrowUp)`
    color: #17C0B9;
    width: 30px;
    height: 30px;
`

export const Collapsed = styled(MdKeyboardArrowDown)`
    color: #17C0B9;
    width: 30px;
    height: 30px;
`