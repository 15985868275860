import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useProposal } from '../providers/ProposalProvider';

import styled from 'styled-components'

//COMPONENTS
import Header from './Proposal/Header';
import Body from './Proposal/Body';
import Error from './Proposal/Error';
import Price from './Proposal/Price';
import Checkout from './Checkout';
import { Edit } from './Proposal/Edit';

const ProposalContainer = styled.div`
    margin: 0 auto;

    @media (max-width: 768px) {
        height auto;
    }
    
`

function Proposal(){
    let { id } = useParams();
    const { getProposal, loading, found, checkout, proposal, setLoading } = useProposal();
    


    useEffect(() => {
        async function wait(){
            
            await getProposal(id!)

            if(!proposal.paidAt && proposal.status !== "PAID") setLoading(false);

        }
        wait();
    }, [id, getProposal]);


    return (

            <ProposalContainer>
                <Header loading={loading}/>
                {proposal.paidAt && proposal.status === "PAID" && <Navigate replace to={`/${proposal.id}/complete`} />}
                {!loading && !checkout && found && proposal.status !== "EDIT" && proposal.status !== "PAID" &&
                <>
                    <Body/>
                    <Price />
                </>
                }
                {!loading && !found && <Error />}
                {!loading && found && checkout && proposal.status !== "EDIT" && <Checkout />}
                {!loading && found && proposal.status === "EDIT" && <Edit />}

            </ProposalContainer>

    )
}

export default Proposal;