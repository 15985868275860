import styled from 'styled-components'

import { 
    Accordion as MUIAccordion,
    AccordionSummary as MUIAccordionSummary,
    AccordionDetails as MUIAccordionDetails,

} from '@mui/material';

export const Accordion = styled(MUIAccordion)`
`

export const AccordionSummary = styled(MUIAccordionSummary)`
    background-color: #FAFAFA !important;  
`

export const AccordionDetails = styled(MUIAccordionDetails)`
`

export const SummaryText = styled.h2`
`