import { createStyles, Title, Text, Container, Group, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useProposal } from '../../../providers/ProposalProvider';
import { completeBooking } from '../../../services/APIService';
const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 120,
    [theme.fn.smallerThan('sm')]: {
        paddingTop: 200,
    },
    //center vertically
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },

  label: {
    textAlign: 'center',
    fontFamily: `Montserrat`,
    fontWeight: 900,
    fontSize: 40,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color: "#17C0B9",

    [theme.fn.smallerThan('sm')]: {
      fontSize: 30,
    },
  },

  title: {
    fontFamily: `Montserrat`,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 40,
    color: "#17C0B9",

    [theme.fn.smallerThan('sm')]: {
      fontSize: 35,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
    color: "#17C0B9",
    fontSize: 24,
    fontWeight: 400,
    fontFamily: `Montserrat`,
  },
}));



export default function Success() {
    const { classes } = useStyles();

    const navigate = useNavigate();
    const { proposal, setLoading, skipBooking, express } = useProposal();

    useEffect(() => {

        const timer = setTimeout(() => {
            setLoading(true)
            //console.log("skip booking" , skipBooking, proposal.booking_status)
            if(skipBooking){
              completeBooking({ id: proposal.id, booked: false, country: proposal.country, manual: true })
              .then(res => {
                navigate("/" + proposal.id + "/complete");
              })
            }
            else {
              navigate("/" + proposal.id + "/booking");
            }


        }, 5000);
        return () => clearTimeout(timer);
        
    }, []);

    return (
        <div className={classes.root}>
          <Container>
              <Title className={classes.title}>Payment complete.</Title>
              <Text size="lg" align="center" className={classes.description}>
                  Thank you - you will now be redirected.
              </Text>
              <Group position="center">
                  <Loader color="#17C0B9" size="xl" />
              </Group>
          </Container>
        </div>
    );
}