import styled from 'styled-components'
import { useProposal } from '../../providers/ProposalProvider';

const PaidAlert = styled.div`
    border-radius: 30px;
    background: white;
    outline: 3px solid #17C0B9;
    width: 90%;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
`

const PaidText = styled.p`
    margin: 5px auto;
    font-size: 18px;
    width: 100%;
    display: inline-block;
    line-height: 1.5;

`

const PaidDate = styled.p`
    margin: 5px auto;
    font-size: 15px;
    width:100%;
`

const PaidDetails = styled.p`
    width: 800px;   
    margin: 10px auto;
    font-size: 18px;
    line-height: 1.5;
`

const PaidBold = styled.span`
    font-weight: 600;
`

const Link = styled.a`
    color: #17C0B9;
    font-weight: 600;
`

function Paid(){
    const { paidAt , proposal } = useProposal();

    const Engagement = proposal.country === "AU" ? "https://www.sprintlaw.com.au/engagement-letter" : "https://www.sprintlaw.co.uk/engagement-letter";
    const Terms = proposal.country === "AU" ? "https://www.sprintlaw.com.au/terms-and-conditions" : "https://www.sprintlaw.co.uk/terms-and-conditions";
    const CompanyNumber  = (): string => {
        if(proposal.company_number === null || proposal.company_number === "" ){
            return "";
        }

        if(proposal.country === "AU" && proposal.company_number){
            if(proposal.company_number!.toString().trim().length === 9){
                return "ACN";
            } else {
                return "ABN";
            }
        }

        if(proposal.country === "UK" && proposal.company_number){
            return "Company number";
        }

        return "";
    }
    
    return (
        <PaidAlert>
            <PaidText>This proposal (including Sprintlaw's <Link href={Terms} target="_blank" >T&Cs</Link> and <Link href={Engagement} target="_blank" >Engagement Letter</Link>) was accepted by:</PaidText> 
            <PaidDetails><PaidBold>{proposal.client_name}</PaidBold> {proposal.company_name && <>on behalf of <br /><PaidBold>{proposal.company_name}</PaidBold></>} <PaidBold>{CompanyNumber()} {proposal.company_number}</PaidBold> of <br></br>{proposal.short_address}</PaidDetails>
            <PaidDate>Accepted on {paidAt.toLocaleDateString('en', {weekday: 'long', year: 'numeric', day: 'numeric', month: 'long',  hour: '2-digit', minute: '2-digit', timeZoneName: 'long'})}.</PaidDate>
            <PaidDate>Your estimated turnaround time is <b> {proposal.selected_delivery_date} business days</b>.</PaidDate>
        </PaidAlert>
    )
}

export default Paid;