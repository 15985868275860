import styled from "styled-components";

import { useEffect, useState } from "react";

import { useProposal } from "../providers/ProposalProvider";
import { useNavigate } from 'react-router';
import { Container, Group, Loader, Text, Title, createStyles } from '@mantine/core';
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import Header from "./Proposal/Header";
import LawyerBooking from "./Proposal/Booking/LawyerBooking";
import Error from './Proposal/Error';
import { completeBooking, getAllocatedLawyers, getLawyer } from "../services/APIService";
import { getAuth } from "firebase/auth";
import { HiDocument } from "react-icons/hi";
import { Button } from "@mui/material";

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: 80,
    paddingBottom: 120,
    [theme.fn.smallerThan('sm')]: {
        paddingTop: 200,
    },
    //center vertically
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },

  title: {
    fontFamily: `Montserrat`,
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 40,
    color: "#17C0B9",

    [theme.fn.smallerThan('sm')]: {
      fontSize: 35,
    },
  },

  description: {
    maxWidth: 540,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
    color: "#17C0B9",
    fontSize: 24,
    fontWeight: 400,
    fontFamily: `Montserrat`,
  },
}));

const CheckoutRoot = styled.div`
    position: relative;
`

const Booking = styled(Button)`
    width: 200px;
    margin: 20px 20px !important;

    font-family: 'Montserrat', sans-serif !important;
    border-color: #17C0B9 !important;
    color: #17C0B9 !important;

`
const NoAvailRoot = styled.div`
    width: 600px;
    height: 300px;
    margin: 50px auto;
    text-align: center;
`

function NoLawyers(){
  return (
    <NoAvailRoot>
    No current availabilities for this proposal. <br /> If accepted now, this will be manually allocated.
    </NoAvailRoot>
  )
}

function NoLawyersClient(){
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
        <Title className={classes.title}>No Times Available</Title>
        <Text size="lg" align="center" className={classes.description}>
          Apologies, there are no longer any times available for self-serve booking. <br />
          Our team will assign a lawyer for you within the next business day and contact you via email to schedule a time. <br />
          You’ll now be redirected.
        </Text>
        <Group position="center">
            <Loader color="#17C0B9" size="xl" />
        </Group>
    </Container>
  )
}


export interface LawyerData {
    "Bio": string
    "Calendly URL": string
    "Calendly Event ID": string
    "Email Address": string
    "Photo URL": string
    "Staff Name": string
    "Title": string[]
    "Next Available Time"? : string

}



function Checkout(){
    const auth = getAuth();
    const user = auth.currentUser;
    const [fetch, setFetch] = useState(true);
    const [noLawyers, setNoLawyers] = useState(false);
    const [noLawyersClient, setNoLawyersClient] = useState(false);
    const [loadingLawyers, setLoadingLawyers] = useState(true);
    const [lawyers, setLawyers] = useState<LawyerData[]>([]);
    const { proposal, setLoading, getProposal, loading, found} = useProposal();
    let { id } = useParams();

    const navigate = useNavigate();


    useEffect(() => {
 
        async function wait(){
          setLoading(true)
          await getProposal(id)

          if(!found) {
            //console.log("not found")
            setLoadingLawyers(false)
            return
          }

        }
        wait()
        .catch(err => console.error(err))

        async function skipBooking(){
          await completeBooking({ id: proposal.id, booked: false, country: proposal.country })
        }

        async function noLawyers(){
          //Screen saying "Sorry no availabilities. Our team will allocate for you"
          //console.log("no lawyers")
          setLoadingLawyers(false)
          if(!user){
            setNoLawyersClient(true)
            setTimeout(() => {

              completeBooking({ id: proposal.id, booked: false, country: proposal.country, failed: true})
              .then(() => {
                navigate("/" + proposal.id + "/complete");
              })

            }, 4000);

          } else {
            setNoLawyers(true)
          }
 
        }


        async function getLawyers(){
          if(found && !proposal.booked){
            setFetch(true)
            if(proposal.specific_lawyer !== "" && proposal.specific_lawyer !== undefined && proposal.specific_lawyer !== null) {
              //console.log("specific lawyer")
              getLawyer({id: proposal.id , lawyer: proposal.specific_lawyer, country: proposal.country})
              .then((res) => {
                  //console.log(res.length)
                  if(res.length === 0) {
                    //console.log("skip booking")
                    noLawyers();
  
                  } else {
                    setLoadingLawyers(false)
                    setLawyers(res)
                  }

                  //if(!proposal.paidAt && proposal.status !== "PAID") setLoading(false);
              })
              .catch(err => {
                console.error(err)
                noLawyers();
              })
            } else {
              let data = {
                id: proposal.id,
                value: proposal.value,
                country: proposal.country,
                products: proposal.products,
              }
    
              getAllocatedLawyers(data)
              .then((res) => {

                  if(res.skip_booking){
                    //console.log("skip booking")
                    noLawyers();
                  } else {
                    setLoadingLawyers(false)
                    setLawyers(res.lawyers)
                    //console.log(lawyers.length)
                  }

                  //if(!proposal.paidAt && proposal.status !== "PAID") setLoading(false);
              })
              .catch(err => {
                console.error(err)
                noLawyers();
              })
            }
  
          }

        }


        getLawyers()
        //console.log(proposal, loadingLawyers)
        setLoading(false)
      }, [found]);

     // console.log(fetch)
    return (
        
            <CheckoutRoot>

                <Header loading={loadingLawyers}/>

                {!loadingLawyers && found && proposal.booked && proposal.status !== "PAID"  && !user && <Navigate replace to={`/${proposal.id}/complete`} />}

                
                {!loadingLawyers && found && !proposal.booked && proposal.status === "ACTIVE" && !user && <Error />}


                {!loadingLawyers && found && !proposal.booked && (proposal.status !== "ACTIVE" || user) &&
                <>
                  {user &&
                    <Link style={{textDecoration: "none"}} to={`/${proposal.id}/brief`} >
                      <Booking variant="outlined" startIcon={<HiDocument />} >
                          Back to brief
                      </Booking>
                    </Link>
                  }
                  {!noLawyers ? (!noLawyersClient ? <LawyerBooking lawyers={lawyers} setFetch={setFetch} fetch={fetch}/> : <> </> ): <NoLawyers />}
                  {noLawyersClient && <NoLawyersClient />}
                  

                </>
                }

                {!loadingLawyers && !found && <Error />}

                
                
                
            </CheckoutRoot>
    )
}



export default Checkout;