import styled from 'styled-components'
import Button from '@mui/material/Button';
import LogoWhite from '../../img/LogoWhite.svg'
import { BiCartAlt } from 'react-icons/bi'
import { MdOutlineRemoveShoppingCart } from 'react-icons/md'
import { HiOutlineUsers, HiOutlinePencilAlt } from 'react-icons/hi'
import { IoDocumentsOutline } from 'react-icons/io5'
import { useProposal } from '../../providers/ProposalProvider';
import { Center } from '@mantine/core';
import { useState } from 'react';
import LearnMoreModal from './LearnMoreModal';
import RemoveMembershipModal from './RemoveMembershipModal';


const Container = styled.div`
    width: 314px;
    height: 395px;
    margin: 0 auto;
    position: relative;
    background: linear-gradient(90.02deg, #1F5D70 67.42%, rgba(31, 93, 112, 0) 92.64%), #2FA09C;
    border-radius: 10px;

    @media (min-width: 768px) {
        width: 630px;
        height: 340px;
    }

    @media (min-width: 992px) {
        width: 860px;
        height: 300px;
    }

    @media (min-width: 1300px) {
        margin: 40px auto;
        height: 300px;
        width: 100%;
    }
`

const Logo = styled.img`
    height: 60px;
    width: 60px;
    position: absolute;

    top: 40px;
    left: 66px;

    @media (max-width: 768px) {
        display: none;
    }
`

const Text = styled.p`
    color: white;
    height: 80px;
    width: 525px;
    font-size: 36px;

    position: absolute;
    top: 55px;
    left: 165px;

    font-family: 'Montserrat';

    @media (max-width: 768px) {
        font-size: 22px;
        text-align: center;

        height: 55px;
        width: 264px;

        top: 40px;
        left: 29px;
    }
`

const Value = styled.h2`
    color: white;
    text-align: center;
    font-weight: 700;

    position: absolute;
 
    border: 1px solid white;
    border-radius: 20px;

    font-size: 60px;

    height: 79px;
    width: 149px;

    top: 140px;
    left: 77px;

    @media (min-width: 768px) {
        top: 180px;
        left: 100px;
    }

    @media (min-width: 992px) {
        top: 60px;
        right: 89px;   
        left: unset;
    }

    @media (min-width: 1300px) {
        top: 60px;
        right: 159px;   
        left: unset;
        
        height: 90px;
        width: 180px;
        font-size: 70px;
    }
`
const PreValue = styled.p`
    color: white;
    text-align: center;
    font-size: 13px;

    position: absolute;

    height: 18px;
    width: 100px;

    top: 120px;
    left: 100px;

    @media (min-width: 768px) {
        top: 160px;
        left: 150px;
    }

    @media (min-width: 992px) {
        top: 35px;
        right: 100px;   
        left: unset;

        width: 130px;
    }

    @media (min-width: 1300px) {
        top: 29px;
        right: 170px; 
        left: unset;
        
        height: 90px;
        width: 160px;
        font-size: 18px;
    }
`

const UnderValue = styled.p`
    color: white;

    text-align: center;

    position: absolute;
    top: 223px;
    left: 90px;

    @media (min-width: 768px) {
        top: 265px;
        left: 113px;
    }

    @media (min-width: 992px) {
        top: 150px;
        right: 102px;   
        left: unset;
    }

    @media (min-width: 1300px) {
        top: 165px;
        right: 168px;
        left: unset;
        
        height: 90px;
        width: 160px;
        font-size: 18px;
    }
`

const Discount = styled.p`
    color: white;
    text-align: center;
    font-size: 24px;
    position: absolute;

    top: 249px;
    left: 120px;

    @media (min-width: 768px) {
        top: 285px;
        left: 142px;
    }

    @media (min-width: 992px) {
        top: 190px;
        right: 135px; 
        left: unset;
    }

    @media (min-width: 1300px) {
        top: 190px;
        right: 170px;
        left: unset;
        
        height: 90px;
        width: 160px;
    }
`

const Strike = styled.span`
    background: #C07217;

    height: 3px;
    width: 90px;

    position: absolute;
    top: 263px;
    left: 110px;

    z-index: 1;

    @media (min-width: 768px) {
        top: 298px;
        left: 128px;
    }

    @media (min-width: 992px) {
        top: 202px;
        right: 115px;
        left: unset;
    }

    @media (min-width: 1300px) {
        top: 202px;
        right: 201px;
        left: unset;
    }
`

const CheckoutButton = styled(Button)`
    background: white !important;
    color: black !important;

    height: 50px;
    min-width: 243px !important;

    position: absolute !important;
    top: 300px;
    left: 36px;

    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;

    border-radius: 10px !important;

    
    @media (min-width: 768px) {
        top: 192px;
        right: 70px;
        left: unset;
    }

    @media (min-width: 992px) {
        top: 163px;
        left: 165px;
        width: 250px !important;
    }

    @media (min-width: 1300px) {
        top: 163px;
        left: 165px;

        width: 250px !important;
    }
`

const CenterContainer = styled(Center)`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    
`

const LearnMore = styled(Button)`
    color: white !important;
    height: 50px;
    width: 160px;

    font-family: 'Montserrat' !important;
    font-size: 16px;
    text-align: center;
    text-decoration-line: underline !important;

    position: absolute !important;
    bottom: 0;

    @media (min-width: 768px) {
        top: 232px;
        right: 110px;
        left: unset;
    }

    @media (min-width: 992px) {
        top: 162px;
        right: 275px;
        left: unset;
    }

    @media (min-width: 1300px) {
        top: 163px;
        left: 420px;

        displsy: block;
    }

`

const CallInfo = styled.div`
    color: white;
    position: absolute;
    display: none;
    

    @media (min-width: 992px) {
        display: block;

        width: 250px;
        font-size: 16px;

        bottom: 20px;
        left: 10px;

        & > svg {
            width: 20px;
            height: 20px;
            float: left;
        }
    
        & > p {
            float: right;
            height: 24px;
        }
    }

    @media (min-width: 1300px) {
        display: block;

        font-size: 18px;

        height: 24px;
        width: 300px;

        bottom: 20px;
        left: 30px;
    
        line-height: 24px;
    
        & > svg {
            width: 24px;
            height: 24px;
            float: left;
        }
    
        & > p {
            float: right;
            height: 24px;
        }
` 

const DocInfo = styled.div`
    color: white;
    position: absolute;
    display: none;
    

    @media (min-width: 992px) {
        display: block;

        width: 260px;
        font-size: 16px;

        bottom: 20px;
        left: 290px;

        & > svg {
            width: 20px;
            height: 20px;
            float: left;
        }
    
        & > p {
            float: right;
            height: 24px;
        }
    }

    @media (min-width: 1300px) {
        display: block;

        font-size: 18px;

        height: 24px;
        width: 300px;

        bottom: 20px;
        left: 390px;
    
        line-height: 24px;
    
        & > svg {
            width: 24px;
            height: 24px;
            float: left;
        }
    
        & > p {
            float: right;
            height: 24px;
        }
` 

const PortalInfo = styled.div`
    color: white;
    position: absolute;
    display: none;
    

    @media (min-width: 992px) {
        display: block;

        width: 260px;
        font-size: 16px;

        bottom: 20px;
        left: 590px;

        & > svg {
            width: 20px;
            height: 20px;
            float: left;
        }
    
        & > p {
            float: right;
            height: 24px;
        }
    }

    @media (min-width: 1300px) {
        display: block;

        font-size: 18px;

        height: 24px;
        width: 300px;
        bottom: 20px;
        left: 730px;
    
        line-height: 24px;
    
        & > svg {
            width: 24px;
            height: 24px;
            float: left;
        }
    
        & > p {
            float: right;
            height: 24px;
        }
    }
` 

function MemberUpsell(){


    const { proposal, memberPrice, setMemberUpsell, memberPopup, setMemberPopup} = useProposal();

    const DefaultCost : number = proposal.country === "UK" ? 399 : 799;
    const MembershipDefault = proposal.country === "UK" ? "£399" : "$799";
    const Currency = proposal.country === "UK" ? "£" : "$";

    const [open, setOpen ] = useState(false);

    const handleOpen = () => {


            setOpen(true);

    } 
    

    const handleClose = () =>{
        setOpen(false);
    } 
    
    const handleRemoveClose = () => {
        setMemberPopup(false);
    }
    const handleClick = () => {
        setMemberUpsell(!proposal.member_upsell)
    };

    return(
        <Container>
            <CenterContainer>
                <Logo src={LogoWhite}/>
                <Text>Save <b>{proposal.membership_discount_percentage}%</b> with <b><br />Sprintlaw Membership</b></Text>

                <PreValue>annual fee of</PreValue><Value>{Currency}{memberPrice}</Value>
                <UnderValue>for the first year</UnderValue>
                {DefaultCost !== memberPrice && <><Strike /><Discount> {MembershipDefault} </Discount></>}

                <CheckoutButton onClick={handleClick} disableElevation variant="contained" endIcon={proposal.member_upsell ? <MdOutlineRemoveShoppingCart /> : <BiCartAlt />}>{proposal.member_upsell ? "REMOVE  MEMBERSHIP" :  "ADD TO CHECKOUT"  }</CheckoutButton>
                <LearnMore onClick={handleOpen}>Learn More</LearnMore>

                <CallInfo><HiOutlineUsers /> <p>unlimited calls with lawyers</p></CallInfo>
                <DocInfo><IoDocumentsOutline /> <p>free updates to documents</p></DocInfo>
                <PortalInfo><HiOutlinePencilAlt /> <p>24h access to member portal</p></PortalInfo>
            </CenterContainer>

            <LearnMoreModal open={open} handleClose={handleClose} />
            <RemoveMembershipModal open={memberPopup} handleClose={handleRemoveClose} />
        </Container>
    )
}

export default MemberUpsell;