
import styled from 'styled-components'

import { MdExpandMore } from 'react-icons/md';
import { Accordion, AccordionSummary, AccordionDetails, SummaryText} from './SprintlawAccordion';
import { useProposal } from '../../providers/ProposalProvider';

const FAQContainer = styled.div`
    margin: 20px auto 40px;
`

const FAQText = styled.p`
    line-height: 1.5;
    padding: 10px;
`
const FAQList = styled.ul`
    padding: 10px 30px;
`

const FAQPoint = styled.li`
    padding: 10px;
`

const FAQLink = styled.a`
    color: #17C0B9;
`

function FAQ(){

    const { proposal } = useProposal();

    const MemberLink = proposal.country === 'AU' ? 'https://www.sprintlaw.com.au/membership' : 'https://www.sprintlaw.co.uk/membership';
    const Engagement = proposal.country === "AU" ? "https://www.sprintlaw.com.au/engagement-letter" : "https://www.sprintlaw.co.uk/engagement-letter";
    const ContactEmail = proposal.country === "AU" ? "lawyer@sprintlaw.com.au" : "lawyer@sprintlaw.co.uk"
    const ContactPhone = proposal.country === "AU" ? "1800 730 617" : "08081347754"
    return(
        <FAQContainer> 
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>How do I contact my lawyer once I've paid?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                <FAQText>Once you’ve accepted this proposal, we will allocate you a lawyer who will take care of your legal project. Your allocated lawyer will contact you via email and let you know if there’s anything they need within <b>1 - 2 business days.</b></FAQText>

                <FAQText>Once your lawyer gets started with your project, they will keep you updated as to the estimated delivery time and will let you know if they require any information from you.</FAQText>

                <FAQText>Please also note that our lawyers generally don’t take unscheduled calls as it helps keep things running efficiently. Your lawyer will provide you with an online link to our call booking system - please book in a time using this link whenever you need to chat to your lawyer.</FAQText>

                <FAQText>If you have urgent queries or would like to follow up with your lawyer, you can email us at <FAQLink href={"mailto:"+ContactEmail}>{ContactEmail}</FAQLink> or call us on {ContactPhone} any time.</FAQText>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>How long will it take to complete my project?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>We've set out the estimated delivery time for your project at the bottom of this page.   The delivery time stated below is a guide only and may change depending on your availability, our workload and other unforeseen factors.  We'll endeavour to keep you updated if there is likely to be any change to this delivery time. Additional fees apply for express turnarounds and are subject to your lawyer's availability. Please let our team know if you require express delivery.</FAQText>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>What's included in my service?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>The inclusions, including the deliverables and the amount of consultation time, in each of the items of your service are set out above. You can click the down arrow to expand each item to read the full scope.</FAQText>

                    <FAQText>Please note that, unless expressly stated above:</FAQText>

                    <FAQList>
                        <FAQPoint>Our advice does not include any tax advice, dispute advice, or any negotiation support including corresponding with counterparties.</FAQPoint>
                        <FAQPoint>Any regulatory advice, such as checking that your practices comply with regulations relevant to your industry, are not included in our above scope, unless expressly specified. If you request such advice, we may provide a further quote for that advice.</FAQPoint>
                    </FAQList>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>What format will I receive my documents in?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>We will deliver any final documents to you in Microsoft Word or PDF format.</FAQText>
                </AccordionDetails>
            </Accordion>
            {proposal.country === 'AU' && 
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>Are you a law firm? Who are your lawyers?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>Yes, Sprintlaw is a law firm. Specifically, we are an Incorporated Legal Practice registered with the Law Society of New South Wales.</FAQText>

                    <FAQText>Our legal team is made up of experienced lawyers, who are specialists in various areas of law and hold an Australian legal practising certificate. They have all trained at leading Australian firms, but have left the traditional corporate law world to join us on our mission to create a new and better way of delivering legal services.  Our team has specialist expertise in technology law, intellectual property law, contract drafting and review, corporate law and commercial law.</FAQText>
                </AccordionDetails>
            </Accordion>
            }
            {proposal.country === "UK" &&
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>What is a legal consultancy? Are you a regulated law firm?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>Sprintlaw operates in Australia and the UK. In Australia, Sprintlaw is a regulated law firm and is an incorporated legal practice with number 32845 regulated by the Law Society of New South Wales. In England and Wales, Sprintlaw instead operates as a legal consultancy and is not a traditionally regulated law firm under the Solicitors Regulation Authority (SRA).</FAQText>
                    <FAQText>So what is a legal consultancy? A ‘legal consultancy’ is a new, innovative model of legal practice which has been available in the UK since 25 November 2019 stemming from changes introduced under the SRA Standards and Regulations 2019. The SRA’s stated intention in introducing this model was to “expand the ways in which solicitors can work in unregulated entities to promote increased opportunities for innovation, greater competition and a raising of standards and protections in the unregulated sector“.</FAQText>
                    <FAQText>As part of our legal consultancy model, Sprintlaw UK employs a mix of UK-qualified solicitors and Australian lawyers who provide legal services to our SME clients in the UK.</FAQText>
                    <FAQText>You can read more about our legal consultancy model <FAQLink href="https://sprintlaw.co.uk/faq/" target="_blank">here</FAQLink>.</FAQText>
                </AccordionDetails>
            </Accordion>
            }
            {proposal.country === "UK" && 
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>Do you have professional indemnity insurance?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>Yes, Sprintlaw has professional indemnity cover of £2,000,000 per claim.</FAQText>
                </AccordionDetails>
            </Accordion> 
            }
            <Accordion>
                <AccordionSummary expandIcon={<MdExpandMore />}>
                    <SummaryText>What's included in the Sprintlaw Membership? Do I have to sign up?</SummaryText>
                </AccordionSummary>
                <AccordionDetails>
                    <FAQText>The Sprintlaw Membership is completely optional, but about 75% of our small business clients sign up to it. It's a great way to build an ongoing relationship with Sprintlaw so you'll have a 'go-to' legal firm for all your legal needs.</FAQText>

                    <FAQText>The Sprintlaw Membership includes:</FAQText>

                    <FAQList>
                        <li>30 minute phone consultations with Sprintlaw lawyers</li>
                        <li>10% discount on any fixed-fee legal services</li>
                        <li>Free updates to any documents we’ve prepared for you</li>
                        <li>Free simple legal documents and templates</li>
                        <li>Online legal management portal</li>
                        <li>Online e-signature tool</li>
                    </FAQList>

                    <FAQText>There is no strict limit on the number of phone consultations or free updates you can request. However, we do have a <FAQLink href={Engagement} target="_blank">fair use policy</FAQLink> in place and our ability to respond to your requests is dependent on our legal team’s capacity.</FAQText>
                    
                    <FAQText>You can read more about what's included <FAQLink href={MemberLink} target="_blank">here</FAQLink>.</FAQText>
                </AccordionDetails>
            </Accordion>
        </FAQContainer>
    )
}

export default FAQ;