import styled from 'styled-components'
import SprintlawLogo from '../../img/SprintlawLogo.svg';
import Loader from '../Loader';




const HeaderDiv = styled.div<HeaderDivProps>`
    position: relative;
    height: ${props => props.$loading ? window.screen.height+"px" : "150px"};
    width: ${props => props.$loading ? window.screen.width+"px" : "100%"};
    background: #17C0B9;
    transition: all .5s ease-in-out;
    z-index: 1;


    @media (max-width: 768px) {
        width: 100vw;
    }
`

/*
const Title = styled.h1`
    height: 40px;
    width: 100%;
    padding: 20px;
    text-align: center;
    top: 50%;
    color: white;
    font-size: 40px;
`*/

const Logo = styled.img`
    height: 50px;
    width: 200px;
    margin: 40px auto;
`

const TitleContainer = styled.div`
    height: 100%;
    width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 300px;
        margin: 0 auto;
    }
`


interface HeaderDivProps{
    $loading: boolean
}

interface HeaderProps {
    loading: boolean,
}

function Header(props : HeaderProps){
    const { loading } = props;
    return (
        <HeaderDiv $loading={loading}>
            <TitleContainer>
                <Logo src={SprintlawLogo} alt="Sprintlaw Logo" />
                {loading ? <Loader /> : ''}
            </TitleContainer>


        </HeaderDiv>
    )
}

export default Header;