export const AU_LAWYERS = [
    {
        name: "Tomoyuki Hachigo",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Tomo-300x300.jpg",
        title: "Co-founder & Principal",
        description: "Tomoyuki is an experienced corporate lawyer. He has experience advising blue chip companies such as Virgin Australia, CIMIC, Skilled Group, Macquarie Bank and Mitsubishi in M&A transactions, as well as advising many startups and small businesses on shareholders agreements, MOUs, term sheets, equity agreements and corporate law issues.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Alex Solo",
        img: "https://sprintlaw.com.au/wp-content/uploads/2020/12/Alex-1024x1024.jpg",
        title: "Co-founder & Principal",
        description: "Alex is an experienced technology, media and commercial lawyer, specialising in software licences, SaaS and app agreements and complex service agreements. Alex has an intimate understanding of the tech industry, having previously founded and sold a digital agency and worked as a tech lawyer at a top-tier Australian law firm.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Claudia Hoeben",
        img: "https://sprintlaw.com.au/wp-content/uploads/2021/07/Claudia-Headshot-1024x1024.jpg",
        title: "Legal Team Leader",
        description: "Claudia is Legal Team Leader at Sprintlaw. Claudia has top-tier law firm and in-house experience from Australia, Tokyo and New York, where she has worked on commercial, intellectual property and technology contracts and transactions.",
        linkedin: 'linkedin.com'
    },
    {
        name: "John Marr",
        img: "https://sprintlaw.com.au/wp-content/uploads/2021/02/163A5272-2-1024x1024.jpg",
        title: "Legal Team Leader",
        description: "John is a Legal Team Leader at Sprintlaw. He holds a Master of Laws and previously worked as legal counsel for large organisations and in commercial law firms. He primarily specialises in contracts, transactions and leasing.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Vladimir Kravchenko",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Vlad-300x300.jpg",
        title: "Legal Team Leader",
        description: "Vladimir is a commercial lawyer, with specialisation in commercial contracts and regulatory advice. He previously worked as an in-house counsel for one of Australia’s big banks, advising on regulatory enquiries, AFSL and ACL issues and drafting and reviewing general commercial contracts.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Amelia Abbott",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Amelia-300x300.jpg",
        title: "Legal Team Leader",
        description: "Amelia is a commercial and corporate lawyer, with specialisation in technology-related contracts, privacy and corporate law. Previously working for two national law firms, she has acted for major public sector clients, including the Australian Federal Police, Australia Post and the Department of Defence, as well as advising many tech startups and SMEs.",
        linkedin: 'linkedin.com'
    },
    {
        name: "Embeth Sadie",
        img: "https://sprintlaw.com.au/wp-content/uploads/2019/08/Embeth-300x300.jpg",
        title: "Senior Lawyer",
        description: "Embeth is a lawyer at Sprintlaw. Having previously practised at a commercial litigation firm, Embeth has a deep understanding of commercial law and how to identify the legal needs of businesses.",
        linkedin: 'linkedin.com'
    },
]