import axios from 'axios';

import { IProposal, IPaymentIntent, IPaymentComplete, ICompletedPayment, IAllocateLawyer, IBookingComplete} from '../types/Proposal';

export async function getProposal (id : string) : Promise<IProposal>{
    return await axios.get(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/${id}`).then(res => res.data).catch(e => console.error(e))
}

export async function getBrief (id : string) : Promise<IProposal>{
    return await axios.get(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/${id}/brief`).then(res => res.data).catch(e => console.error(e))
}

export async function setupPayment ( price : number, country : string, id: string ) : Promise<IPaymentIntent>{
    return await axios.post(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/payment/intent`, { price : price , country: country, id: id}).then(res => res.data).catch(e => console.error(e))
}

export async function completePayment ( proposal : ICompletedPayment) : Promise<IPaymentComplete>{
    return await axios.post(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/payment/complete`,  proposal ).then(res => res.data).catch(e => console.error(e))
}

export async function lookupABN ( abn : string ) : Promise<any>{
    return await axios.get(`https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abn}&callback=callback&guid=06fd8d23-0758-48e3-8ec0-a59352719c2d`).then(res => res.data).catch(e => console.error(e))
}

export async function lookupACN ( acn : string ) : Promise<any>{
    return await axios.get(`https://abr.business.gov.au/json/AcnDetails.aspx?acn=${acn}&callback=callback&guid=06fd8d23-0758-48e3-8ec0-a59352719c2d`).then(res => res.data).catch(e => console.error(e))
}

export async function getAllocatedLawyers ( data : IAllocateLawyer) : Promise<any>{
    return await axios.post(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/lawyer/allocate`, data).then(res => res.data).catch(e => console.error(e))
}

export async function checkAvailability ( id : string) : Promise<any> {
    return await axios.get(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/lawyer/availability/${id}`).then(res => res.data).catch(e => console.error(e))
}

export async function completeBooking (data : IBookingComplete) : Promise<any> {
    return await axios.post(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/booking/complete/`, data).then(res => res.data).catch(e => console.error(e))
}

export async function getLawyer(data : { lawyer : string, country : string, id : string, inviteeURL? : string, eventURL?: string}) : Promise<any> {
    return await axios.post(`https://${process.env.REACT_APP_API}.sprintlaw.co/proposal/lawyer/`, data).then(res => res.data).catch(e => console.error(e))
}