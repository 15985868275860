import styled from 'styled-components'
import { useProposal } from '../../providers/ProposalProvider';
import { AiFillExclamationCircle } from 'react-icons/ai';

const ExpiredAlert = styled.div`
    border-radius: 30px;
    background: white;
    outline: 3px solid #F97A7A;
    width: 80%;
    text-align: center;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;


    
`

const ExpiredText = styled.h1`
    margin: 0 auto;
    font-size: 18px;
`

const Date = styled.span`
    color: #FF2222;
`

const Exclamation = styled(AiFillExclamationCircle)`
    float: left;
    color: red;
    width: 40px;
    height: 40px;
    margin-left: 40px;

    @media (max-width: 768px) {
        display: none;
    }
`

function Expired(){
    const { expiryDate, proposal } = useProposal();


    const AU_EMAIL = proposal.client_number ? "clients@sprintlaw.com.au" : "team@sprintlaw.com.au";
    const UK_EMAIL = "team@sprintlaw.co.uk"

    return (
        <ExpiredAlert>
            <Exclamation />
            <ExpiredText>This proposal expired on <Date>{expiryDate.toLocaleDateString('en', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Date>. Please contact {proposal.country == "AU" ? AU_EMAIL : UK_EMAIL } for more information.</ExpiredText>
        </ExpiredAlert>
    )
}

export default Expired;