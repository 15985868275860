import styled from 'styled-components'

import QuoteTable from './QuoteTable';
import FAQ from './FAQ';
import MemberUpsell from './MemberUpsell';
import { useProposal } from '../../providers/ProposalProvider';
import AddonsTable from './AddonsTable';
import Expired from './Expired';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HowItWorks from './HowItWorks';
import OurLawyers from './OurLawyers';
import { MdOutlineHelp } from 'react-icons/md';
import AdditionalNotes from './AdditionalNotes';
import GovTable from './GovTable';

import parse from 'html-react-parser';
import Paid from './Paid';


const BodyContainer = styled.div`
    margin: 0 auto;
    width: 100%;
    height: fit-content;


    @media (min-width: 768px) {
        width: 720px;
    }

    @media (min-width: 992px) {
        width: 960px;
    }

    @media (min-width: 1300px) {
        width: 1200px;
    }

`

const ProposalBody = styled.div`
    width: 90%;
    height: 100%;
    margin: 40px auto;
`

const ClientName = styled.h1`
    width: 100%;
    text-align: left;
    padding: 50px 0 10px;
    font-size: 18px;
`
const Brief = styled.p`
    width: 100%;
    text-align: left;
    padding: 10px 0;
    font-size: 16px;
`

const TableHeader = styled.h1`
    padding: 40px 0;
    font-size: 23px;
    color: #17C0B9;
    font-weight: 600;
    width: 100%;
    text-align: center;
`
interface TooltipProps {
    label : string
    aria : string
}




const HeaderTooltip = styled(IconTooltip)`
    float: right !important;
    height: 40px;
    width: 40px;
`
const Help = styled(MdOutlineHelp)`
    color: #17C0B9;
    width: 30px;
    height: 30px;

`

const TooltipText = styled.h1`
    font-size: 20px;
`

function IconTooltip(props : TooltipProps){
    const { label, aria} = props;

    return (
        <Tooltip title={<TooltipText>{label}</TooltipText>} 
            componentsProps={{
                tooltip: {
                    sx: {
                        lineHeight: '2rem',
                    },
                },
            }}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
          >
            <IconButton aria-label={aria}><Help /></IconButton>
        </Tooltip>
    )
}






function Body() {
    const { proposal, optionalProducts, expired, govProducts, taxType, products } = useProposal();
    
    
    const GovText = `These are the Government Fees & Disbursements associated with some of the fixed fee packages listed above. No ${taxType} is payable on these fees.${proposal.member_upsell ? " Member discounts do not apply to government fees." : ""}`
    const OptionalText = "While these products are not mandatory, they are reccomended based on your consultation with our team."
    const SelectPackagesText = "Select one or more of the packages below."

    const OptionalHeader = products.length > 0 ? "Optional Add-ons" : "Select Packages"
    const OptionalTool = products.length > 0 ? OptionalText : SelectPackagesText
    return (
        <BodyContainer>
            <ProposalBody>


                {expired && 
                    <Expired />
                }
                
                {proposal.paidAt && proposal.status === "PAID" && <Paid />}

                <ClientName>Dear {proposal.client_name}</ClientName>
                <Brief>{parse(proposal.proposal_brief)}</Brief>

                {products.length > 0 &&
                <>
                    <TableHeader>Your Packages</TableHeader>
                    <QuoteTable />
                </>
                }

                {optionalProducts.length > 0 &&  
                <>
                    <TableHeader>{OptionalHeader} <HeaderTooltip label={OptionalTool} aria="Optional Addons Tooltip" /></TableHeader>
                    <AddonsTable />
                </>
                }

                {govProducts.length > 0 &&
                <>
                    <TableHeader>Government Fees & Disbursements <HeaderTooltip label={GovText} aria="Government Fees Tooltip" /></TableHeader>
                    <GovTable/>
                </>
                }

                <TableHeader>How It Works</TableHeader>
                <HowItWorks />
                {proposal.show_member_upsell && <MemberUpsell />}

                <TableHeader>Our Lawyers</TableHeader>
                <OurLawyers />
                
                <TableHeader>FAQ</TableHeader>
                <FAQ />

                {proposal.additional_notes && 
                <>
                    <TableHeader>Additional Notes</TableHeader>
                    <AdditionalNotes />
                </>}


            </ProposalBody>
        </BodyContainer>
    )
}

export default Body;

