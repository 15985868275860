

import styled from 'styled-components'

//COMPONENTS
import Header from './Proposal/Header';
import Error from './Proposal/Error';

const ProposalContainer = styled.div`
    margin: 0 auto;

    @media (max-width: 768px) {
        height auto;
    }
`

function NotFound(){
    

    return (

            <ProposalContainer>
                <Header loading={false}/>
                <Error />
            </ProposalContainer>

    )
}

export default NotFound;